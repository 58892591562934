module.exports = [
  // K-12
  {
    rank: 71,
    priority: "Help Every Student Succeed",
    priorityShort: "Help Every Student Succeed",
    slug: "help-every-student-succeed",
    description:
      "Understand students’ needs in and out of the classroom, track progress in real time, and coordinate the best possible support for each child to thrive — all while maximizing cost savings.",
    category: "industryType",
    industryX: "education",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Sales",
      "Service",
      "Marketing",
      "Tableau",
      "MuleSoft",
      "Data Cloud",
      "Slack",
      "Industries",
      "Customer Success",
    ],
    benefitTitle1: "Get 360-Degree Student Views",
    benefitDesc1:
      "Unify disparate student data in one, secure location. Understand the whole student, strengthen relationships, and gain insights through unified data.",
    benefitTitle2: "Deliver Holistic Student Support",
    benefitDesc2:
      "Identify students in need and intervene at scale with data-driven alerts. Assign personalized care plans to help students achieve goals, complete processes, or address challenges.",
    benefitTitle3: "Prepare Students for College and Career",
    benefitDesc3:
      "Support different student demographics and identify which needs attention. Create graduation pathways and monitor progress against college-ready milestones.",
    benefitTitle4: "Track Alumni and Postsecondary Success",
    benefitDesc4:
      "See how many graduates are enrolling in college, monitor ongoing progress, and track time to graduation.",
    categoryOffer: "industryType",
    offerSection: "education",
    testimonial: {
      title:
        "See How Oakland Unified School District's Solution Drives Results",
      quote:
        "We had a shared vision about how to use technology to best serve our students. With Salesforce, we can bring together a variety of information and data points in a single place.",
      attributionName: "Curtiss Sarikey",
      attributionTitle: "Chief of Staff, Oakland Unified School District",
      image: "",
      logo: "ousd.jpg",
      storyLink:
        "https://www.salesforce.org/stories/oakland-unified-school-district/",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UfHyAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHuAAK",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJrAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Higher-education
  {
    rank: 79,
    priority: "Enhance Employee Service with Connected Data",
    priorityShort: "Enhance Employee Service with Connected Data",
    slug: "enhance-employee-service-with-connected-data",
    description:
      "Enhance employee experiences and deliver better service by connecting siloed data, streamlining processes, and enabling faculty and staff to work from anywhere — all while maximizing cost savings.",
    category: "industryType",
    industryX: "education",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Marketing",
      "Sales",
      "Service",
      "Tableau",
      "MuleSoft",
      "Customer Success",
      "Slack",
    ],
    benefitTitle1: "Enhance the Faculty and Staff Experience",
    benefitDesc1:
      "Create amazing employee experiences through communities and apps. Build tailored marketing campaigns, manage faculty and staff wellness, enable volunteering, and empower employees to work from anywhere.",
    benefitTitle2: "Optimize Research Operations",
    benefitDesc2:
      "Simplify research by optimizing lab operations and improving collaboration among cross-functional working groups. Track and enhance corporate relationships while streamlining the commercialization lifecycle.",
    benefitTitle3: "Improve Facilities Management",
    benefitDesc3:
      "Enable consistent, connected service across campus. Maximize efficiencies, remove manual processes, and automate facility operations. Contain costs, track utilization, and enhance processes based on real-time data.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "industryType",
    offerSection: "education",
    testimonial: {
      title: "See How Arizona State University's Solution Drives Results",
      quote:
        "Having all of the information about a constituent in one place – presented in a logical way – has transformed the way we communicate.",
      attributionName: "David Burge",
      attributionTitle:
        "Executive Director of Admission Services, Arizona State University",
      image: "",
      logo: "arizona.jpg",
      storyLink: "https://www.salesforce.org/stories/arizona-state-university/",
    },
    video: {
      videoID: "2SruJqMwSEqGSJQ3WgwjEW",
      eyebrow: "Watch Video",
      title: "Unlock the Power of Digital Campus",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002K7VwAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHrAAK",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIoAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Small businesstype
  {
    rank: 1,
    priority: "Get Up and Running Fast",
    priorityShort: "Get Up and Running Fast",
    slug: "get-up-and-running-fast",
    description:
      "Salesforce Starter is the fastest and easiest way to get started with a complete CRM portfolio. Built-in guidance and prebuilt tools help you get the most out of your CRM from Day 1. Connect and align sales, service, and marketing to unlock insights across your business that will help you find, win, and keep customers.",
    category: "businessType",
    industryX: "",
    roleX: "",
    businessTypeX: "small-business",
    productGrid: ["Sales", "Service", "Marketing", "Customer Success"],
    benefitTitle1: "Start Instantly with Simple Setup",
    benefitDesc1:
      "In-app tutorials, guided setup, and connected email accelerate your start. And you can master the tools quickly with Trailhead, our free online learning platform.",
    benefitTitle2: "Sell and Support Faster and Smarter",
    benefitDesc2:
      "Find all the sales data you need in one place, so you can focus on selling. Automated tools capture every email and meeting. An all-in-one suite ensures you never miss key details from your service and marketing efforts.",
    benefitTitle3: "Craft Compelling Marketing Campaigns",
    benefitDesc3:
      "Discover new customers by leveraging CRM data for impactful email campaigns. AI tools facilitate automated emails at the most engaging times.",
    benefitTitle4: "Scale As You Grow",
    benefitDesc4:
      "While this solution feels tailored just for you, it’s still Salesforce — it’s built to grow with you. Upgrade and expand effortlessly when you’re ready, and our regular updates will keep you ahead of the curve.",
    categoryOffer: "businessType",
    offerSection: "small-business",
    testimonial: {
      title: "See How Snapology’s Solution Drives Results",
      quote:
        "Starter has been fantastic. The setup and onboarding were easy to do on my own, and I’m very pleased with the amount of functionality I get—the reporting, the email integrations, and everything else.",
      attributionName: "Adam Ruben",
      attributionTitle: "Owner, Snapology of Lehi",
      image: "",
      logo: "snapology.png",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/snapology/",
    },
    // video: {
    //   videoID: "pNMx1ongKusvKP91juuLAb",
    //   eyebrow: "Watch Video",
    //   title: "Salesforce Essentials, the #1 CRM, Made for Small Business",
    // },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcYRAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJbwAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJcAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 31,
    priority: "Find Better Leads",
    priorityShort: "Find Better Leads",
    slug: "find-better-leads",
    description:
      "It’s time to start building a quality pipeline. Unite your marketing and sales strategies, so your teams are laser-focused on finding and nurturing qualified leads. Powerful email tools make it easy to create smart campaigns with dynamic content. And smart scoring and routing tools make sure every lead goes to the right rep.",
    category: "businessType",
    industryX: "",
    roleX: "",
    businessTypeX: "small-business",
    productGrid: ["Marketing", "Slack", "Customer Success", "Sales"],
    benefitTitle1: "Generate Better Leads, Faster",
    benefitDesc1:
      "Turn cold leads into sales-ready prospects with automated nurture tracks. You’ll free up your team to focus on other tasks at the same time.",
    benefitTitle2: "Create Targeted Emails That Build Qualified Pipeline",
    benefitDesc2:
      "Marketing emails are a reflection of your company. Marketing Cloud Account Engagement (formerly known as Pardot) can help you look like a pro. Create dynamic emails your customers want to read. And use A/B testing to pinpoint the best approach.",
    benefitTitle3: "Score, Route, and Nurture Every Lead ",
    benefitDesc3:
      "Automatic lead scoring can help you spot future customers. Use rules-based routing to send leads to the right rep and close more sales. And do it all while nurturing prospects who aren’t ready to buy.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "businessType",
    offerSection: "small-business",
    // testimonial: {
    //   title: "See How Bitty & Beau's Solution Drives Results",
    //   quote:
    //     "Our customers mean so much to us, and we want them to continue to feel connected to our small business and our mission.",
    //   attributionName: "Amy Wright",
    //   attributionTitle: "Founder and CEO, Bitty & Beau's",
    //   image: "amy_wright.png",
    //   logo: "bitty_beaus.jpg",
    //   storyLink: "https://www.salesforce.com/blog/how-a-growing-coffee-chain-boosted-its-online-business-during-covid-19/",
    // },
    video: {
      videoID: "MuqAr2pKcEZ84WqMzAj9d2",
      eyebrow: "Watch Video",
      title:
        "Connect Sales Teams with the Best Leads To Close More Deals, Faster",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcRaAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJc1AAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJ4AAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 2,
    priority: "Win More Deals",
    priorityShort: "Win More Deals",
    slug: "win-more-deals",
    description:
      "Make it easier to win new customers. Eliminate repetitive tasks and streamline your sales process, which will give your employees more time to connect with customers. Keep all of your customer information in one place, so you can get clear insights and spot the best opportunities.",
    category: "businessType",
    industryX: "",
    roleX: "",
    businessTypeX: "small-business",
    productGrid: ["Sales", "Slack", "Customer Success"],
    benefitTitle1: "Automate Your Routine Work",
    benefitDesc1:
      "Give your team a permanent break from repetitive tasks. Automate the routine work and give every employee more time to sell.",
    benefitTitle2: "Streamline Your Sales Process",
    benefitDesc2:
      "Build the sales process that works for your small business. Prioritize leads. Focus on the right prospects. Close more deals. And give every employee the tools to sell from anywhere.",
    benefitTitle3: "Centralize Your Customer Information",
    benefitDesc3:
      "Give your team a single source of customer data. Discover powerful insights and hot opportunities. And spot issues before they become problems.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "businessType",
    offerSection: "small-business",
    // testimonial: {
    //   title:"See How Bitty & Beau's Solution Drives Results",
    //   quote:
    //     "Our customers mean so much to us, and we want them to continue to feel connected to our small business and our mission.",
    //   attributionName: "Amy Wright",
    //   attributionTitle: "Founder and CEO, Bitty & Beau's",
    //   image: "amy_wright.png",
    //   logo: "bitty_beaus.jpg",
    //   storyLink: "https://www.salesforce.com/blog/how-a-growing-coffee-chain-boosted-its-online-business-during-covid-19/",
    // },
    video: {
      videoID: "9MxUJWwQDQoHNAryfvvpp3",
      eyebrow: "Watch Video",
      title: "Grow Your Business w/ Sales Cloud | Salesforce Demo",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcRWAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJc6AAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJEAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 32,
    priority: "Keep Customers Happy",
    priorityShort: "Keep Customers Happy",
    slug: "keep-customers-happy",
    description:
      "No matter how big or small your company is, customers expect outstanding service. Give your employees the tools they need to quickly help any customer, from anywhere, on any device.",
    category: "businessType",
    industryX: "",
    roleX: "",
    businessTypeX: "small-business",
    productGrid: ["Service", "Slack", "Customer Success"],
    benefitTitle1: "Help More Customers",
    benefitDesc1:
      "Make every employee a customer support expert. Automate time-consuming admin tasks. Solve customer issues faster. And help your team deliver great service for more customers.",
    benefitTitle2: "Get a Complete View of Every Customer",
    benefitDesc2:
      "Get a single view of every customer across sales, service, and marketing. And discover the insights that can help your team offer personalized service at every step.",
    benefitTitle3: "Connect with Your Customers on Any Channel",
    benefitDesc3:
      "Your customers aren’t just contacting you by phone. Deliver the kind of support they expect across social media, text, phone, and more. Chatbots can answer common questions and eliminate wait times.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "businessType",
    offerSection: "small-business",
    testimonial: {
      title: "See How Bitty & Beau's Solution Drives Results",
      quote:
        "Our customers mean so much to us, and we want them to continue to feel connected to our small business and our mission.",
      attributionName: "Amy Wright",
      attributionTitle: "Founder and CEO, Bitty & Beau's",
      image: "amy_wright.png",
      logo: "bitty_beaus.jpg",
      storyLink:
        "https://www.salesforce.com/blog/how-a-growing-coffee-chain-boosted-its-online-business-during-covid-19/",
    },
    video: {
      videoID: "cR4Uvaw4FSWWvykR4mAbWM",
      eyebrow: "Watch Video",
      title:
        "Deliver Faster, Smarter, More Personalized, and Conversational Service",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcRfAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJc7AAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIqAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Commerce
  {
    rank: 25,
    priority: "Go Direct to Consumer",
    priorityShort: "Go Direct to Consumer",
    slug: "go-direct-to-consumer",
    description:
      "Open new revenue channels and reach your customers, at scale, where they are with AI. Use real-time insights and data to optimize your strategy fast to maximize revenue.",
    category: "roleType",
    industryX: "",
    roleX: "commerce",
    businessTypeX: "",
    productGrid: ["Commerce", "Marketing", "MuleSoft", "Customer Success"],
    benefitTitle1: "Fuel Growth with the Power of Trusted AI",
    benefitDesc1:
      "Experience accelerated ROI and sales growth with AI, powered by your trusted data, that uncovers opportunities and enhances decision-making.",
    benefitTitle2: "Personalize at Scale with Real-Time, Harmonized Data",
    benefitDesc2:
      "Increase customer satisfaction with dynamic shopping experiences, personalized offers, and seamless buying journeys with real-time, harmonized data.",
    benefitTitle3: "Sell on Every Customer Touchpoint",
    benefitDesc3:
      "Meet your customers where they are, with flexible commerce experiences designed to engage across sales, service, and marketing channels.",
    categoryOffer: "roleType",
    offerSection: "commerce",
    testimonial: {
      title: "See How PUMA's Solution Drives Results",
      quote:
        "The site is faster. The user experience is better, and the conversion rate is better. Full stop.",
      attributionName: "Ken Kralick",
      attributionTitle: "Global Head of Ecommerce, PUMA",
      image: "ken_kralick.png",
      logo: "puma.jpg",
      storyLink: "https://www.salesforce.com/customer-success-stories/puma/",
    },
    video: {
      videoID: "A4DeVYZE8fYzuDP9U3ZNmJ",
      eyebrow: "Watch Demo",
      title:
        "Sell Online Fast, with the World's Most Trusted Commerce Platform",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcTPAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002Z4ZDAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m61VAAQ",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 26,
    priority: "Make Business Buying Online Easy with AI",
    priorityShort: "Make Business Buying Online Easy with AI",
    slug: "make-business-buying-online-easy-with-ai",
    description:
      "Grow profits and reduce costs by making business buying as easy as shopping online. Use AI to facilitate quick launches at scale, broadening your customer base exponentially. Cultivate buyer loyalty through flawless interactions across channels setting a new standard for customer satisfaction.",
    category: "roleType",
    industryX: "",
    roleX: "commerce",
    businessTypeX: "",
    productGrid: ["Commerce", "Sales", "Service", "Slack", "Customer Success"],
    benefitTitle1: "Fuel Profits and Reduce Costs with AI",
    benefitDesc1:
      "Supercharge ROI and drive sales growth with AI powered by your trusted data, uncovering hidden opportunities and optimizing decision-making.",
    benefitTitle2: "Personalize at Scale with Real-Time, Harmonized Data",
    benefitDesc2:
      "Foster lasting loyalty and effortless engagement by delivering dynamic shopping experiences, personalized offers, and tailored buying journeys through real-time, harmonized data.",
    benefitTitle3: "Launch with Speed and Scale",
    benefitDesc3:
      "Adapt and thrive by meeting your customers on every front — flexible commerce experiences designed to engage across channels and scale with you.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "roleType",
    offerSection: "commerce",
    testimonial: {
      title: "See How GE Renewable Energy’s Solution Drives Results",
      quote:
        "We’ve already done 3x more volume through our new platform than we did the entire previous year on the old platform.",
      attributionName: "Uzair Memon",
      attributionTitle: "Chief Commercial Officer, GE Renewable Energy",
      image: "",
      logo: "ge-energy.png",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/general-electric-b2b-storefront/?d=cta-body-promo-298",
    },
    video: {
      videoID: "vXztLYMLLXNtsxRpkLTqco",
      eyebrow: "Watch Video",
      title:
        "Quickly Deliver Personalized Ecommerce Websites, Designed for Your Business Customers",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcTDAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002Z4ZIAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m61yAAA",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Executive
  {
    rank: 4,
    priority: "Responsible AI for Your Business",
    priorityShort: "Responsible AI for Your Business",
    slug: "responsible-ai-for-your-business",
    description:
      "Get real-time access to all your customer data, powerful analytics, and automation across your business to make AI work for you. With trusted AI, data, and CRM, your marketing, sales, commerce, service, IT, and data teams are smarter and more productive than ever.",
    category: "roleType",
    industryX: "",
    roleX: "executive",
    businessTypeX: "",
    productGrid: [
      "Sales",
      "Service",
      "Marketing",
      "MuleSoft",
      "Tableau",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Empower Teams with Trusted AI",
    benefitDesc1:
      "With AI built for CRM, your teams are more productive than ever, generating relevant content at scale, right out of the box. And with the Einstein Trust Layer, your data is always private and protected.",
    benefitTitle2: "Deliver Seamless Experiences",
    benefitDesc2:
      "Connect all your data with the #1 AI CRM to give every team a complete view of every customer. With your data united, your teams can deliver the right experience, at the right time, every time.",
    benefitTitle3: "Drive Productivity",
    benefitDesc3:
      "Teams can do more, faster when they have the right tool for every job. Boost results and delight customers by uniting all your teams around your customers with best-in-class apps powered by Sidekick.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "roleType",
    offerSection: "executive",
    testimonial: {
      title: "See how L’Oréal’s Solution Drives Results",
      quote:
        "Technology is moving fast, and competition is running after us. When we saw the development of this new technology and the possibilities with smartphones and virtual reality, we had to jump on it.",
      attributionName: "Nicolas Hieronimus",
      attributionTitle: "CEO, L’Oréal",
      image: "",
      logo: "loreal.png",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/loreal-data-unique-beauty-experiences/?d=cta-header-2",
    },
    video: {
      videoID: "Tp6ANWw5e9f4R9jMx4533u",
      eyebrow: "Watch Video",
      title: "AI + Data + CRM. That’s the genius of Customer 360",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcYeAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHOAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m624AAA",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 21,
    priority: "Make Your Data an Asset",
    priorityShort: "Make Your Data an Asset",
    slug: "make-data-an-asset-2",
    description:
      "Unleash the potential of your unique data to generate new revenue streams. Enable your team with impactful insights for faster, better business decisions. With Tableau Embedded Analytics, offer seamless, data-rich experiences across your organization, customizing visualizations to fit your brand.",
    category: "roleType",
    industryX: "",
    roleX: "executive",
    businessTypeX: "",
    productGrid: [
      "Tableau",
      "Data Cloud",
      "MuleSoft",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Increase Business Profitability",
    benefitDesc1:
      "See your business by the numbers with intuitive dashboards that identify opportunities for revenue growth, increased customer loyalty, and areas of development.",
    benefitTitle2: "Automate Insights",
    benefitDesc2:
      "Drive efficiencies and cost savings by embedding data-driven processes into buyer and supplier channels.",
    benefitTitle3: "Power Better Business Decisions",
    benefitDesc3:
      "Provide suppliers with connected, real-time insights into their brands, from primary sales data to category web analytics.",
    benefitTitle4: "Monetize Your Data",
    benefitDesc4:
      "Transform your data into dollars. Treat data as a valuable business asset to create new revenue streams, enhance customer relationships, and improve operational efficiency.",
    categoryOffer: "roleType",
    offerSection: "executive",
    testimonial: {
      title: "See How Ocado’s Solution Drives Results",
      quote:
        "Tableau helps our retail supply partners to achieve clarity. The dashboards we deliver provide the embedded analytics they need to become more agile, putting data at the center of their decision-making",
      attributionName: "JACK JOHNSON",
      attributionTitle: "Head of Retail Data at Ocado Retail",
      image: "",
      logo: "ocado.png",
      storyLink:
        "https://www.tableau.com/solutions/customer/ocado-retail-monetises-its-wealth-data-tableau-embedded-analytics",
    },
    video: {
      videoID: "N9VFwj5WTsdvZBySTyBN9G",
      eyebrow: "Watch Story",
      title: "Seamlessly Embed the Self-Service Analytics Your Customers Want",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002aQKUAA2",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002aQKUAA2",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002aQLcAAM",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Non profit
  {
    rank: 66,
    priority: "Build Relationships and Fund Impact",
    priorityShort: "Build Relationships and Fund Impact",
    slug: "build-relationships-and-fund-impact",
    description:
      "Get a holistic view of constituents with trusted, harmonized data to connect more effectively with the right supporters. Help fundraisers trust their critical data is always secure.",
    category: "industryType",
    industryX: "nonprofit",
    roleX: "",
    businessTypeX: "",
    productGrid: ["Industries", "Slack", "Customer Success"],
    benefitTitle1: "Get One View of Every Supporter",
    benefitDesc1:
      "Connect supporters to a shared view of information on one platform. Diversify revenue and support all fundraising strategies. Get CRM insights from data as donations and payments come in.",
    benefitTitle2: "Move Faster with Digital-First Fundraising",
    benefitDesc2:
      "Reimagine donor experiences with new digital strategies in mind. Launch new forms of online giving and create data-driven donation and payment experiences.",
    benefitTitle3: "Grow Donor Relationships",
    benefitDesc3:
      "Unlock opportunities to grow relationships and create moments of delight along the donor journey. Fundraise productively anywhere across systems, team collaboration, and devices and drive visibility and transparency into development activities, forecasts, and finances.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "industryType",
    offerSection: "nonprofit",
    testimonial: {
      title: "See How Riley Children’s Foundation's Solution Drives Results",
      quote:
        "Predictive modeling can be very expensive. We would have to spend a lot more time compiling our data from Salesforce to provide our leadership team and gift officers with insight. Having CRM Analytics and Einstein Discovery just makes all of that a lot faster and easier for our team.",
      attributionName: "Samantha Lame-Farley",
      attributionTitle: "Manager, Fundraising Data Analytics",
      image: "",
      logo: "rcf.png",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/riley-childrens-foundation-boosts-fundraising-with-ai-powered-insights/",
    },
    video: {
      videoID: "JowtLiysJT2JugLxLENRv4",
      eyebrow: "Watch Story",
      title: "Create Donor-Centric Experiences That Drive Fundraising",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002K7W1AAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJapAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnItAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 69,
    priority: "Grow Your Community and Strengthen Relationships",
    priorityShort: "Grow Your Community and Strengthen Relationships",
    slug: "grow-your-community-strengthen-relationships",
    description:
      "Increase awareness, start new relationships, and build on existing ones. Develop a deeper understanding of the things that matter most to stakeholders. Engage them in more personalized ways, at a lower cost.",
    category: "industryType",
    industryX: "nonprofit",
    roleX: "",
    businessTypeX: "",
    productGrid: ["Industries", "Customer Success", "Slack"],
    benefitTitle1: "Engage Constituents with Empathy",
    benefitDesc1:
      "Get a complete picture of each supporter so you can personalize how you engage with them. Build powerful supporter experiences and get insights that drive sustained giving and support.",
    benefitTitle2: "Find and Acquire New Supporters at Scale",
    benefitDesc2:
      "Attract and nurture relationships with new supporters. Build a strong community by engaging target audiences in more personalized ways.",
    benefitTitle3: "Humanize the Moments that Matter",
    benefitDesc3:
      "Lead with empathy and create personalized, meaningful experiences that build supporter relationships.",
    benefitTitle4: "Optimize Your Marketing Performance, Budget, and ROI",
    benefitDesc4:
      "Integrate analytics from any platform to optimize supporter engagement and maximize your budget. Share results across your organization for greater transparency.",
    categoryOffer: "industryType",
    offerSection: "nonprofit",
    testimonial: {
      title: "See How Little Kids Rock's Solution Drives Results",
      quote:
        "Salesforce has been the organizational nerve center for Little Kids Rock. We use it in virtually every aspect of our work.",
      attributionName: "Dave Wish",
      attributionTitle: "Founder & Chief Executive Officer, Little Kids Rock",
      image: "",
      logo: "lkr.jpg",
      storyLink: "https://www.salesforce.org/stories/little-kids-rock/",
    },
    video: {
      videoID: "JowtLiysJT2JugLxLENRv4",
      eyebrow: "Watch Story",
      title: "Find and Engage Lifelong Supporters",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002K7WGAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJb3AAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJ3AAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // IT
  {
    rank: 23,
    priority: "Build and Extend Your Customer 360 with AI + Data + CRM",
    priorityShort: "Build and Extend Your Customer 360 with AI + Data + CRM",
    slug: "build-extend-your-customer-360-with-ai-data-crm",
    description:
      "Enable your teams to implement, build, and extend your Customer 360, securely. Streamline development projects, innovate with speed and trust, and automate complex processes across your organization.",
    category: "roleType",
    industryX: "",
    roleX: "it",
    businessTypeX: "",
    productGrid: ["Sales", "Data Cloud", "Slack", "Customer Success"],
    benefitTitle1: "Launch Fast with Simplified DevOps",
    benefitDesc1:
      "Simplify how teams build, test, and release updates with a low-code DevOps pipeline tailored to your team’s active development projects.",
    benefitTitle2: "Scale Your Business with Multiple Sandboxes",
    benefitDesc2:
      "Play in more sandboxes, simultaneously. Expand faster and lower technology costs through multi-sandbox adoption, including add-ons, employee training, and parallel development.",
    benefitTitle3: "Improve Efficiency with AI-Generated Code",
    benefitDesc3:
      "Spend less time coding, and more time innovating. Extend the value of CRM by deploying, managing, and scaling bespoke applications and complex CRM automation using open coding languages and open data stores.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "roleType",
    offerSection: "it",
    testimonial: {
      title: "See How ENGIE's Solution Drives Results",
      quote:
        "25 million customers have evolved in their expectations. Salesforce is helping us globally to address these demands by a faster and real time understanding of their expectations.",
      attributionName: "Yves Le Gelard",
      attributionTitle:
        "Executive Vice President, Digital & Information Systems, ENGIE",
      image: "yves_legelard.png",
      logo: "engie.jpg",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/unifying-data-greener-future-engie/",
    },
    video: {
      videoID: "H5wnCmUrgRPN52Xm8xASod",
      eyebrow: "Watch Video",
      title: "Empower Your Teams To Innovate Fast From Anywhere",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcTMAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHBAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m628AAA",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 24,
    priority: "Secure and Protect Your Data",
    priorityShort: "Secure and Protect Your Data",
    slug: "secure-protect-your-data",
    description:
      "Ensure your data is protected and compliant with Salesforce Security and Privacy solutions. Leverage our native, purpose-built tools to mitigate risk and manage changing data regulations, all while building secure apps on the world’s #1 CRM.",
    category: "roleType",
    industryX: "",
    roleX: "it",
    businessTypeX: "",
    productGrid: ["Data Cloud", "MuleSoft", "Slack", "Customer Success"],
    benefitTitle1: "Understand Data Exposure",
    benefitDesc1:
      "Quickly review access controls and permissions to ensure the right people have the right access. With automatic regulation updates, your teams can easily reclassify information to stay compliant.",
    benefitTitle2: "Protect Sensitive Data",
    benefitDesc2:
      "Reduce risk to protect your sensitive data through smart encryption, flexible key management, and masking data across your platforms.",
    benefitTitle3: "Monitor for Anomalies",
    benefitDesc3:
      "Track changes to sensitive data including key events, allowing you to increase app performance and user adoption while eliminating blind spots.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "roleType",
    offerSection: "it",
    testimonial: {
      title: "See How PwC's Solution Drives Results",
      quote:
        "Salesforce Shield enabled us to understand with a deeper level of granularity what's happening in the environment and even prevent bad things from happening.",
      attributionName: "Andrea Acciarri",
      attributionTitle: "Principal, Cyber, Risk and Regulatory, PwC",
      image: "",
      logo: "pwc.png",
      storyLink: "",
    },
    video: {
      videoID: "YguHZxD5ZwXRTNdd5WsJHL",
      eyebrow: "Watch Video",
      title: "Shield Overview Demo",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcTOAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuH7AAK",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m61zAAA",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // marketing
  {
    rank: 27,
    priority: "Scale Personalized Engagement Across Channels with AI",
    priorityShort: "Scale Personalized Engagement Across Channels with AI",
    slug: "scale-personalized-engagement-across-channels-with-ai",
    description:
      "Grow relationships with AI-driven cross-channel marketing automation. Activate customer data to deliver personalized campaigns, journeys, and real-time moments that boost customer satisfaction.",
    category: "roleType",
    industryX: "",
    roleX: "marketing",
    businessTypeX: "",
    productGrid: [
      "Marketing",
      "Sales",
      "Service",
      "Commerce",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Activate Customer Data at Scale with AI",
    benefitDesc1:
      "Identify target audiences, as well as the best time to reach them and how often with AI to increase customer engagement.",
    benefitTitle2: "Deliver Personalized Content Across the Customer Lifecycle",
    benefitDesc2:
      "Create and deliver AI-powered customer journeys with personalized offers and content across marketing, commerce, and service.",
    benefitTitle3: "Maximize Marketing Effectiveness",
    benefitDesc3:
      "Optimize spend and performance using AI dashboards and insights to reduce acquisition costs, increase engagement, and grow customer lifetime value.",
    benefitTitle4: "Increase Productivity with Generative and Predictive AI",
    benefitDesc4:
      "Boost personalization and productivity, with predictive and generative AI built into our apps. Generate emails and build campaign landing pages, all optimized based on your CRM data and prior results.",
    categoryOffer: "roleType",
    offerSection: "marketing",
    testimonial: {
      title: "See How General Mills’ Solution Drives Results",
      quote:
        "AI, Data, and CRM are how we achieve the promise of speaking directly to consumers with the thing that matters most to them.”",
      attributionName: "Doug Martin",
      attributionTitle:
        "Chief Brand and Disruptive Growth Officer, General Mills",
      image: "",
      logo: "general-mills.png",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/general-mills-consumer-engagement-ai-data/",
    },
    video: {
      videoID: "9n2RPwDYVujGyyzwgLbKcd",
      eyebrow: "Watch Video",
      title: "Connect every cross-channel moment — efficiently and at scale",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcYYAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHMAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJLAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 28,
    priority: "Activate Targeted Marketing with a Unified Customer Profile",
    priorityShort:
      "Activate Targeted Marketing with a Unified Customer Profile",
    slug: "activate-targeted-marketing-with-unified-customer-profile",
    description:
      "Unlock the full potential of data and AI with our customer data platform (CDP) that’s built into our #1 CRM. Power personalized moments and engage customers smarter than ever before.",
    category: "roleType",
    industryX: "",
    roleX: "marketing",
    businessTypeX: "",
    productGrid: [
      "Marketing",
      "Sales",
      "Service",
      "Commerce",
      "Customer Success",
      "MuleSoft",
      "Tableau",
      "Slack",
    ],
    benefitTitle1: "Unify All Your Data into a Single Customer Profile",
    benefitDesc1:
      "Connect first-party data from any source into one place to create a unified customer profile with prebuilt integrations for marketing, commerce, sales, service, and more.",
    benefitTitle2: "Engage Smarter with Dynamic Segments",
    benefitDesc2:
      "Create dynamic segments to activate tailored journeys, campaigns, and moments for each customer.",
    benefitTitle3: "Maximize Relevance with Automated Insights",
    benefitDesc3:
      "Gain insight into your audience segments, campaign performance, and customer propensities with embedded AI to quickly make adjustments and maximize results.",
    benefitTitle4: "Deliver Personalized Engagement Across Every Touchpoint",
    benefitDesc4:
      "Activate data to personalize engagement across channels, trigger workflows, and enrich existing CRM data to deliver the connected experience customers crave.",
    categoryOffer: "roleType",
    offerSection: "marketing",
    testimonial: {
      title: "See How Humana’s Solution Drives Results",
      quote:
        "To build trust with your members, you need to really know them — and data tells their story.",
      attributionName: "Chris Walker",
      attributionTitle: "AVP, Engagement Marketing, Humana",
      image: "",
      logo: "humana.png",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/humana-data-building-patient-trust/",
    },
    video: {
      videoID: "GbB33tgT2zvAf935E7rTEN",
      eyebrow: "Watch Video",
      title: "Discover the power of Data Cloud for Marketing",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcYZAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJaeAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJMAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Program Managment
  // Sales
  {
    rank: 22,
    priority: "Accelerate Deals with Data and AI",
    priorityShort: "Accelerate Deals with Data and AI",
    slug: "accelerate-deals-with-data-ai",
    description:
      "Use AI to obtain data insights that matter, accelerating at every stage of the customer buying journey. Predict customer behavior, automate tasks, and provide real-time insights, leading to increased sales efficiency and improved outcomes.",
    category: "roleType",
    industryX: "",
    roleX: "sales",
    businessTypeX: "",
    productGrid: [
      "Sales",
      "Data Cloud",
      "Tableau",
      "Slack",
      "Customer Success",
      "MuleSoft",
    ],
    benefitTitle1: "Make Data-Driven Decisions",
    benefitDesc1:
      "Speed up cycles with dedicated deal rooms and automate deal approval flows. Make purchasing easy with quick, accurate quotes with the help of Sales Assistant.",
    benefitTitle2: "Pivot Your Sales Strategy",
    benefitDesc2:
      "Optimize sales territories and planning so reps spend their time in the right place with the right people. Recognize revenue faster with complete quote-to-cash.",
    benefitTitle3: "Drive Better Business Outcomes",
    benefitDesc3:
      "Accelerate productivity with AI-powered automation, incorporating call summaries and automated email generation, to swiftly deliver valuable call and relationship insights. Understand pipeline health and gaps to increase focus on the deals that matter most.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "roleType",
    offerSection: "sales",
    testimonial: {
      title: "See How Zillow's Solution Drives Results",
      quote:
        "Salesforce brings automation and scaled technology to our workforce, so they can work with our customers better.",
      attributionName: "Jeremy Wacksman",
      attributionTitle: "President, Zillow",
      image: "jeremy_wacksman.png",
      logo: "zillow.jpg",
      storyLink: "https://www.salesforce.com/customer-success-stories/zillow/",
    },
    video: {
      videoID: "4AMG94B7deFLvnZNZrkAmk",
      eyebrow: "Watch Video",
      title: "How to Scale Up Your Sales Processes",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcQSAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJarAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m61tAAA",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 64,
    priority: "Activate Growth in Every Channel",
    priorityShort: "Activate Growth in Every Channel",
    slug: "activate-growth-in-every-channel",
    description:
      "Leverage AI to maximize customer touchpoints across every channel and accelerate revenue booking. Quickly gauge customer satisfaction and improve services, while recommendation systems boost cross-selling opportunities.",
    category: "roleType",
    industryX: "",
    roleX: "sales",
    businessTypeX: "",
    productGrid: ["Sales", "Commerce", "MuleSoft", "Slack", "Customer Success"],
    benefitTitle1: "Create Efficiencies",
    benefitDesc1:
      "Automate the quote-to-cash cycle enhanced with AI recommendations to streamline sales processes, reduce manual tasks, and empower sales teams to make more informed decisions. Give your team a holistic view of their customers quickly with a unified, integrated platform.",
    benefitTitle2: "Reduce Cost",
    benefitDesc2:
      "Reduce the cost of hiring by selling more through partners. Scale routine deals by eliminating rep intervention and reducing labor costs to free up sales teams to focus on more complex and strategic tasks.",
    benefitTitle3: "Increase Productivity",
    benefitDesc3:
      "Reclaim valuable time for your teams through automated sales tasks and reminder processes. Leverage AI-powered insights to analyze customer data, identify potential opportunities, and determine optimal moments to involve customers in deal huddles with Slack.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "roleType",
    offerSection: "sales",
    testimonial: {
      title: "See How Zillow's Solution Drives Results",
      quote:
        "Salesforce brings automation and scaled technology to our workforce, so they can work with our customers better.",
      attributionName: "Jeremy Wacksman",
      attributionTitle: "President, Zillow",
      image: "jeremy_wacksman.png",
      logo: "zillow.jpg",
      storyLink: "https://www.salesforce.com/customer-success-stories/zillow/",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcTHAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJbcAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m623AAA",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Service
  {
    rank: 30,
    priority: "Increase Agent Productivity with AI",
    priorityShort: "Increase Agent Productivity with AI",
    slug: "increase-agents-productivity-with-ai",
    description:
      "End the multiscreen, swivel-chair era of service once and for all. Empower your agents with the right tools to be more productive and collaborative, from anywhere. Use AI-powered recommendations and automation to close cases faster, build deeper relationships, and scale your service.",
    category: "roleType",
    industryX: "",
    roleX: "service",
    businessTypeX: "",
    productGrid: [
      "Service",
      "Tableau",
      "MuleSoft",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Accelerate Resolutions with Trusted AI",
    benefitDesc1:
      "Generate responses to customer questions based on prior results to speed up resolution time. Agents can verify the response or edit to better personalize the experience for the customer — accelerating their work and improving customer satisfaction.",
    benefitTitle2: "Scale Support and Deliver Consistent Experiences",
    benefitDesc2:
      "Support many cases across any channel with a unified workspace. Use intelligent routing and automation to send cases to the right agent, with the right context. Ensure consistent service every time, at every touchpoint.",
    benefitTitle3:
      "Accelerate Knowledge, Collaboration, and Training Across Teams",
    benefitDesc3:
      "Give agents the most relevant information to handle cases fast. Save agents time by automatically creating case summaries and knowledge base articles.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "roleType",
    offerSection: "service",
    testimonial: {
      title: "See How OneUnited Bank’s Solution Drives Results ",
      quote:
        "Service Cloud has allowed us to deliver empathetic service at scale.",
      attributionName: "Teri Williams",
      attributionTitle: "COO, OneUnited Bank",
      image: "teri_williams.png",
      logo: "one_united_bank.jpg",
      storyLink:
        "https://www.salesforce.com/resources/videos/digital-tools-service-oneunited-bank/",
    },
    video: {
      videoID: "9n2RPwDYVujGyyzwgLbKcd",
      eyebrow: "Watch Video",
      title:
        "Deliver Faster, Smarter, More Personalized, and Conversational Service",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcYgAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJaaAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJXAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 55,
    priority: "Elevate Every Field Service Experience",
    priorityShort: "Elevate Every Field Service Experience",
    slug: "elevate-every-field-service-experience",
    description:
      "Customers won’t forgive disappointing field service just because logistics are tough. Empower your mobile workers and field service teams to deliver proactive and excellent support by giving them all the customer information they need in one AI-powered mobile app.",
    category: "roleType",
    industryX: "",
    roleX: "service",
    businessTypeX: "",
    productGrid: ["Service", "Sales", "Slack", "Customer Success"],
    benefitTitle1: "Supercharge Field Productivity with Trusted AI",
    benefitDesc1:
      "Achieve more with mobile work briefings and onsite knowledge search accelerating first-time fix rates with access to key information quickly. And, once the job is completed, AI helps to provide quick and accurate post-work summaries.",
    benefitTitle2: "Fuel CSAT with Real-Time Personalization",
    benefitDesc2:
      "Unify your data with Data Cloud for a complete view of customers and assets, ensuring the best front-line teams for the job arrive onsite armed with the right tools and information.",
    benefitTitle3: "Bring the Office to the Field",
    benefitDesc3:
      "Give your mobile workforce an easy, all-in-one app, so they can capture notes, photos, and job duration, all seamlessly synchronized with the office. With real-time updates and offline functionality, this app gives a whole new meaning to work from anywhere.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "roleType",
    offerSection: "service",
    testimonial: {
      title: "See How GE Appliances’s Solution Drives Results",
      quote:
        "Our partnership with Salesforce enables us to be closer to every single GE Appliances user.",
      attributionName: "Viren Shah",
      attributionTitle: "CDO, GE Appliances",
      image: "",
      logo: "ge-appliances.jpg",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/ge-appliances-virtual-proactive-service/",
    },
    video: {
      videoID: "QXaq4WCZvtQT7LZv2mzL6h",
      eyebrow: "Watch Video",
      title: "Deliver Efficient, Proactive, and Elevated Field Service With AI",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcYXAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJbiAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJFAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Ind. Communications
  {
    rank: 15,
    priority: "Maximize Lead Conversion Online, on the Phone, and in Stores ",
    priorityShort:
      "Maximize Lead Conversion Online, on the Phone, and in Stores",
    slug: "maximize-lead-conversion-online-on-the-phone-and-in-stores",
    description:
      "Use the power of AI and data to win new customers. Get a complete view of every prospect, enabling personalized marketing journeys that boost win rates across all channels. Optimize interactions at every stage of the sale to deliver a compelling and conversion-driven experience.",
    category: "industryType",
    industryX: "communications",
    roleX: "",
    businessTypeX: "",
    productGrid: ["Industries", "Marketing", "Commerce", "Sales", "Slack"],
    benefitTitle1: "Customer and Prospect Personalization at Scale",
    benefitDesc1:
      "Harness AI and data to gain a holistic view of prospects, allowing for personalized marketing journeys that increase conversion rates.",
    benefitTitle2: "Data-Driven Optimization for Sales Interactions",
    benefitDesc2:
      "Use data-driven insights to optimize interactions at each stage of the sales process, ensuring a compelling and successful customer experience.",
    benefitTitle3: "",
    benefitDesc3: "",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "industryType",
    offerSection: "communications",
    testimonial: {
      title: "See How Telstra Enterprise's Solution Drives Results",
      quote:
        "For the first time, we have everybody collaborating on the same platform for the benefit of our customers.",
      attributionName: "Mirko Gropp",
      attributionTitle: "Digital Adoption Principle, Telstra Enterprise",
      image: "mirko_gropp.png",
      logo: "telstra.jpg",
      storyLink:
        "https://www.salesforce.com/au/customer-success-stories/telstra/",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSGAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002Z4Z9AAK",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnInAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 16,
    priority: "Deliver Customer Service That Builds Loyalty",
    priorityShort: "Deliver Customer Service That Builds Loyalty",
    slug: "deliver-customer-service-that-builds-loyalty",
    description:
      "Empower your service teams with the power of AI and data to resolve cases quickly while building customer loyalty. Equip agents with a comprehensive view of every customer and proactive tools to address issues, transforming them into profit centers.",
    category: "industryType",
    industryX: "communications",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Service",
      "MuleSoft",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Create Dynamic Customer Insights",
    benefitDesc1:
      "Provide service teams with a complete view of every customer, quickly, by enabling personalized and efficient issue resolution. Use AI to quickly generate responses, summarize cases, and generate articles with ease.",
    benefitTitle2: "Proactive Support with the Full Power of Data",
    benefitDesc2:
      "Equip agents with data-driven tools to proactively address customer issues, fostering subscriber loyalty and long-term relationships.",
    benefitTitle3: "",
    benefitDesc3: "",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "industryType",
    offerSection: "communications",
    testimonial: {
      title: "See How Telstra Enterprise's Solution Drives Results",
      quote:
        "For the first time, we have everybody collaborating on the same platform for the benefit of our customers.",
      attributionName: "Mirko Gropp",
      attributionTitle: "Digital Adoption Principle, Telstra Enterprise",
      image: "mirko_gropp.png",
      logo: "telstra.jpg",
      storyLink:
        "https://www.salesforce.com/au/customer-success-stories/telstra/",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSqAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002Z4ZNAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIfAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // ConsumerGood
  {
    rank: 12,
    priority: "Unlock Perfect Retail Execution",
    priorityShort: "Unlock Perfect Retail Execution",
    slug: "unlock-perfect-retail-execution",
    description:
      "Transform your retail execution to optimize planning, automate processes, and achieve flawless store execution on a unified platform. Maximize sales and reduce inefficiencies as you ensure products are in the right place at the right time, capturing customer decisions at the shelf.",
    category: "industryType",
    industryX: "consumer-goods",
    roleX: "",
    businessTypeX: "",
    productGrid: ["Industries", "MuleSoft", "Customer Success", "Slack"],
    benefitTitle1: "Maximize Selling Time",
    benefitDesc1:
      "Automate and streamline tasks to efficiently plan and prioritize visits. Ensure your route is optimized to minimize the seller’s time spent driving and maximize time with their accounts.",
    benefitTitle2: "Drive Growth",
    benefitDesc2:
      "Empower sellers with store-level insights and built-in analytics to increase efficiency. Utilize real-time data with predictive intelligence to make smarter, faster decisions.",
    benefitTitle3: "Improve Strategy",
    benefitDesc3:
      "Equip headquarters with real-time field data to identify planogram gaps and address out-of-stock issues effectively. Quickly manage assets and stay on top of market trends.",
    benefitTitle4: "Deliver Perfect Store Execution",
    benefitDesc4:
      "Activate headquarters brand plans with guided selling, in-store collateral, and account-specific product lists and order templates.",
    categoryOffer: "industryType",
    offerSection: "consumer-goods",
    testimonial: {
      title: "See How Balaji Wafers’s Solution Drives Results",
      quote:
        "With smarter field planning, our reps are more productive. The number of visits to partners has increased by 30% per rep per week.",
      attributionName: "Shyam Virani",
      attributionTitle: "Director of Sales, Balaji Wafers",
      image: "",
      logo: "balaji-wafers.png",
      storyLink:
        "https://www.salesforce.com/in/customer-success-stories/balaji-wafers/",
    },
    video: {
      videoID: "88F9175C6BEKbSLSfpRnH6?",
      eyebrow: "Watch Video",
      title: "Deliver the perfect store with the #1 AI CRM for Consumer Goods",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSiAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002Z4ZSAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJSAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 11,
    priority: "Improve Trade Promotion Effectiveness",
    priorityShort: "Improve Trade Promotion Effectiveness",
    slug: "improve-trade-promotion-effectiveness",
    description:
      "Maximize trade promotion effectiveness and unlock the potential with streamlined planning, intelligent budget management, and real-time tracking across all trading activities. Drive profitable growth and achieve higher levels of customer satisfaction with data-driven decision-making and tailored promotion strategies.",
    category: "industryType",
    industryX: "consumer-goods",
    roleX: "",
    businessTypeX: "",
    productGrid: ["Industries", "Customer Success", "MuleSoft", "Tableau"],
    benefitTitle1: "Streamline Promotion Planning",
    benefitDesc1:
      "Make intelligent baseline and uplift predictions with real-time data. Build dynamic promotion plans, allocate trade funds, and optimize trade spending on one platform.",
    benefitTitle2: "Manage Funds and Budgets",
    benefitDesc2:
      "Gain clear visibility to trade budgets and checkbooks, including live rate and dynamic funding.",
    benefitTitle3: "Simplify Claims Management",
    benefitDesc3:
      "Efficiently handle claims and deductions reconciliations while streamlining settlement processes.",
    benefitTitle4: "Report in Real Time",
    benefitDesc4:
      "Deploy dashboards at the product, store, and account level for a clear view of your trade spend. Optimize trade programs down to the tactic level to deliver KPIs.",
    categoryOffer: "industryType",
    offerSection: "consumer-goods",
    testimonial: {
      title: "See How Sanofi’s Solution Drives Results",
      quote:
        "We believe Trade Promotion Management will enable us to engage our customers more appropriately, deliver better outcomes for shoppers and consumers and simplify our business",
      attributionName: "Mark Jagger",
      attributionTitle: "Global Commercial Head, Sanofi CHC",
      image: "",
      logo: "sanofi.png",
      storyLink: "",
    },
    video: {
      videoID: "ynL5rPof5DTCGaZFZww6px?",
      eyebrow: "Watch Video",
      title: "Maximize profitability with intelligent trade planning",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSnAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002Z4ZJAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIRAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Energy
  {
    rank: 50,
    priority: "Transform Utility Customer Engagement",
    priorityShort: "Transform Utility Customer Engagement",
    slug: "transform-utility-customer-engagement",
    description:
      "Give agents a complete view of every customer, plus digital tools to serve and sell from anywhere. Reduce costs and increase customer satisfaction with guided journeys for enrollment, analytics, and program engagement.",
    category: "industryType",
    industryX: "energy",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Sales",
      "Service",
      "Marketing",
      "Customer Success",
      "Slack",
    ],
    benefitTitle1: "Empower Agents",
    benefitDesc1:
      "Equip agents and internal teams with an AI-driven omni-channel contact center and a single shared view of the customer to deliver truly personalized service across channels.",
    benefitTitle2: "Boost Acquisition and Reduce Churn",
    benefitDesc2:
      "Stand out in a competitive market by quickly optimizing offers and generating complex quotes. Rapidly process market and customer data to deliver relevant product and service bundles.",
    benefitTitle3: "Reimagine the Customer Experience",
    benefitDesc3:
      "Increase customer satisfaction with digital self-service. Connect to customers in ways they want, with chatbots, appointment scheduling, and relevant offerings, all on mobile.",
    benefitTitle4: "Integrate with Core Utility Systems",
    benefitDesc4:
      "Accelerate innovation with prebuilt integrations that seamlessly connect to core utility CIS/billing systems to create a modern front-office experience.",
    categoryOffer: "industryType",
    offerSection: "energy",
    testimonial: {
      title: "See How ENGIE's Solution Drives Results",
      quote:
        "25 million customers have evolved in their expectations. Salesforce is helping us globally to address these demands by a faster and real time understanding of their expectations.",
      attributionName: "Yves Le Gelard",
      attributionTitle:
        "Executive Vice President, Digital & Information Systems, ENGIE",
      image: "yves_legelard.png",
      logo: "engie.jpg",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/unifying-data-greener-future-engie/",
    },
    video: {
      videoID: "ynibmX8KyX7K6nswgNcbv8",
      eyebrow: "Watch Video",
      title:
        "Personalize Customer Engagements with Salesforce Energy and Utilities Cloud",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002K7VmAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHfAAK",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIWAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 51,
    priority: "Streamline Field Operations",
    priorityShort: "Streamline Field Operations",
    slug: "streamline-field-operations",
    description:
      "Lower field operations costs and minimize downtime by streamlining work execution and predictably managing assets. Create an intelligent, mobile-first workplace and improve safety and collaboration by optimizing field worker schedules and providing on-the-go access to data and processes.",
    category: "industryType",
    industryX: "energy",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Service",
      "Data Cloud",
      "Tableau",
      "Customer Success",
      "Slack",
    ],
    benefitTitle1: "Empower Field Workers",
    benefitDesc1:
      "Connect field workers to the data and processes needed to successfully and safely complete work tasks. Increase collaboration, improve safety and maximize productivity with a mobile-first workplace.",
    benefitTitle2: "Optimize Asset Performance",
    benefitDesc2:
      "Prevent issues and minimize asset downtime with real-time asset performance visibility. Increase wrench time and improve asset performance with work schedule optimization.",
    benefitTitle3: "Digitize Project Planning",
    benefitDesc3:
      "Equip teams with a 360-degree project view and automated workflows to accelerate project timelines. Lower operating risk and decrease costs.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "industryType",
    offerSection: "energy",
    testimonial: {
      title: "See How ENGIE's Solution Drives Results",
      quote:
        "25 million customers have evolved in their expectations. Salesforce is helping us globally to address these demands by a faster and real time understanding of their expectations.",
      attributionName: "Yves Le Gelard",
      attributionTitle:
        "Executive Vice President, Digital & Information Systems, ENGIE",
      image: "yves_legelard.png",
      logo: "engie.jpg",
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/unifying-data-greener-future-engie/",
    },
    video: {
      videoID: "jBdXxcxCZo6duMtKHtru1b",
      eyebrow: "Watch Video",
      title: "Drive Field Engagement and Asset Service",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002K7VrAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHPAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIeAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Financial services
  {
    rank: 33,
    priority: "Financial Service Innovation",
    priorityShort: "Financial Service Innovation",
    slug: "financial-service-innovation",
    description:
      "Embrace AI-driven automation and personalization to optimize customer interactions, reduce costs, and build lasting loyalty. Let customers self-serve with chatbots while boosting agent productivity through a unified 360-degree view for faster issue resolution.",
    category: "industryType",
    industryX: "financial-services",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Service",
      "MuleSoft",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Anticipate Customers’ Future Needs",
    benefitDesc1:
      "Engage customers through a conversational UI, equipped with a knowledge base and advanced chatbot functionalities.",
    benefitTitle2: "Provide Assistance Quickly",
    benefitDesc2:
      "Empower customer service representatives (CSRs) with instant access to critical information, all within a unified system.",
    benefitTitle3: "Deliver Continuous Data-Driven Engagement",
    benefitDesc3:
      "Foster customer engagement by delivering relevant messages across every channel with data-driven insights.",
    benefitTitle4: "Resolve the Issue Completely",
    benefitDesc4:
      "Facilitate seamless coordination among teams by connecting the middle- and back-office workflows, ensuring complete issue resolution.",
    categoryOffer: "industryType",
    offerSection: "financial-services",
    testimonial: {
      title: "See How RBC Wealth Management’s Solution Drives Results",
      quote:
        "Our number one priority is to deliver world-class wealth management services. For us, this meant we needed to enhance our client and advisor experiences by embracing automation and moving away from inefficient and manual processes",
      attributionName: "Greg Beltzer",
      attributionTitle: "Head of Technology, RBC Wealth Management U.S.",
      image: "",
      logo: "rbc-wm.png",
      storyLink:
        "https://www.salesforce.com/news/press-releases/2022/12/01/customer-success-rbc-wealth-management/",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcRkAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHDAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJ5AAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 5,
    priority: "Scale Personalized Financial Engagement",
    priorityShort: "Scale Personalized Financial Engagement",
    slug: "scale-personalized-financial-engagement",
    description:
      "Harmonize your CRM and financial data and create relevant personalized experiences at scale with predictive and generative AI. Boost customer loyalty and retention with real-time insights, transforming your customer interactions.",
    category: "industryType",
    industryX: "financial-services",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Sales",
      "Service",
      "Marketing",
      "Data Cloud",
      "MuleSoft",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Scale with AI on the #1 CRM",
    benefitDesc1:
      "Unlock the power of AI to generate relevant answers and recommended actions, freeing up service agents to provide better customer service and spend time on higher-value activities.",
    benefitTitle2: "Unify Customer and Financial Data",
    benefitDesc2:
      "Unify data across your organization using connectors and streams, providing real-time access for all your customer processes.",
    benefitTitle3: "Engage with Real-Time Financial Insights",
    benefitDesc3:
      "Put AI-driven insights to work across our best-in-class CRM apps, empowering proactive and informed decision-making.",
    benefitTitle4: "Automate Goal-Planning Experiences",
    benefitDesc4:
      "Create personalized goal-planning experiences, from simple retail bank goals to comprehensive financial plans delivered by wealth advisors.",
    categoryOffer: "industryType",
    offerSection: "financial-services",
    testimonial: {
      title: "See How OneUnited Bank's Solution Drives Results",
      quote:
        "With our banking apps built on Salesforce, we can deliver more services to understand communities.",
      attributionName: "Teri Williams",
      attributionTitle: "President, OneUnited Bank",
      image: "teri_williams.png",
      logo: "one_united_bank.jpg",
      storyLink:
        "https://www.salesforce.com/customer-success-stories/oneunited-bank/",
    },
    video: {
      videoID: "HvMHYGc2vejP2wH4ReUUEY",
      eyebrow: "Watch Video",
      title:
        "Harmonize all customer data to deliver hyper-personalized experiences",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcRuAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuGNAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJ7AAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // HelathCare
  {
    rank: 37,
    priority: "Scale Patient Access and Services ",
    priorityShort: "Scale Patient Access and Services ",
    slug: "scale-patient-access-and-services",
    description:
      "Deliver exceptional customer service while efficiently managing patient relationships. With EHR integration and HIPAA compliance, harness AI, identity verification, and automated workflows to boost agent productivity and provide faster, personalized service to patients, anytime and anywhere.",
    category: "industryType",
    industryX: "healthcare-life-sciences",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Marketing",
      "MuleSoft",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Personalize Every Interaction",
    benefitDesc1:
      "Unlock a comprehensive view of your patients for seamless identity verification and quick, tailored responses across all communication channels.",
    benefitTitle2: "Simplify Patient Access",
    benefitDesc2:
      "Accelerate care lead time with AI-powered recommendations and self-service tools to help you process inbound referrals, confirm benefits, schedule appointments, and complete pre- and post-visit patient visit forms.",
    benefitTitle3: "Boost Patient Engagement",
    benefitDesc3:
      "Give patients access to on-demand health records and services so they can engage in their care journey anytime, anywhere.",
    benefitTitle4: "Gain Insights and Measure Success",
    benefitDesc4:
      "Give staff real-time data with operational reports, purpose-built dashboards, and advanced analytics allowing them to keep track of what is working and what is not.",
    categoryOffer: "industryType",
    offerSection: "healthcare-life-sciences",
    testimonial: {
      title: "See How Piedmont Healthcare’s Solution Drives Results",
      quote:
        "We needed to operate as one health system, even though we have 11 hospitals and hundreds of access points. And we had to serve patients consistently, even though they had different needs.",
      attributionName: "Katie Logan",
      attributionTitle: "Vice President, Experience, Piedmont Healthcare",
      image: "piedmonthealthcare.png",
      logo: "piedmont.jpg",
      group: true,
      storyLink:
        "https://www.salesforce.com/resources/articles/piedmont-healthcare-covid-response/",
    },
    video: {
      videoID: "sW9kqT8oSQ6igDbDBBmBrp",
      eyebrow: "Watch Video",
      title:
        "Boost Contact Center Productivity with the World’s  #1 Healthcare CRM",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcS9AAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuGcAAK",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIlAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 8,
    priority: "Generate Intelligent Care Orchestration for Payers",
    priorityShort: "Generate Intelligent Care Orchestration for Payers",
    slug: "generate-intelligent-care-orchestration-for-payers",
    description:
      "Drive improved health outcomes and enable more equitable, efficient, whole-person care through data sourcing and unification of health plans. Engage patients in a whole new way, proactively establish actionable care plans, and promote measurable progress toward improved outcomes.",
    category: "industryType",
    industryX: "healthcare-life-sciences",
    roleX: "",
    businessTypeX: "",
    productGrid: ["Industries", "MuleSoft", "Slack", "Customer Success"],
    benefitTitle1: "Engage Your Members",
    benefitDesc1:
      "Members can take an active role in their health by tracking their progress on any device. And they can connect with their care team on any channel, through personalized, secure portals.",
    benefitTitle2: "Get a 360-Degree View of Members",
    benefitDesc2:
      "Integrate member data into a single view, from benefits and claims to clinical events and wellness programs. Simplify processes with a collaboration map that shows key relationships across household members, care plans, and care teams.",
    benefitTitle3: "Collaborate in Real Time",
    benefitDesc3:
      "Collaboration tools help care teams work together to make the best decisions for every member. They can easily see the status of open care requests, along with coverage and guidelines, to streamline approvals.",
    benefitTitle4: "Intelligent Prioritization for Case Managers",
    benefitDesc4:
      "With AI-powered insights into benefits, preapprovals, clinical data, and member activity, case managers can prioritize the right steps for the best outcomes.",
    categoryOffer: "industryType",
    offerSection: "healthcare-life-sciences",
    testimonial: {
      title: "See How Piedmont Healthcare's Solution Drives Results",
      quote:
        "With information changing every day — sometimes multiple times a day — Salesforce has been integral in our ability to operate.",
      attributionName: "Katie Logan",
      attributionTitle: "Chief Consumer Officer, Piedmont Healthcare",
      image: "piedmonthealthcare.png",
      logo: "piedmont.jpg",
      group: true,
      storyLink:
        "https://www.salesforce.com/resources/articles/piedmont-healthcare-covid-response/",
    },
    video: {
      videoID: "eCVfHASP58pTkfb6u33NHM",
      eyebrow: "Watch Video",
      title: "Scale Member Care Management",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSJAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuGYAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJ9AAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 40,
    priority: "Drive Intelligent Sales in MedTech",
    priorityShort: "Drive Intelligent Sales in MedTech",
    slug: "drive-intelligent-sales-in-medtech",
    description:
      "Deliver a seamless, fully connected virtual selling experience, on one platform, lowering costs and driving faster business outcomes. Make engagement more than video conferencing, and close more business with intelligent, actionable insights.",
    category: "industryType",
    industryX: "healthcare-life-sciences",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Sales",
      "Marketing",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Engage Prospects at Every Step",
    benefitDesc1:
      "Speed up sales cycles by sending prospects the right message, at the right time, on the channel of their choice.",
    benefitTitle2: "Uncover and Prioritize Opportunities for Growth",
    benefitDesc2:
      "Spot buying patterns across groups and facilities. Use built-in lead scoring, contact management tools, and AI-informed next actions to advance bigger deals faster than ever.",
    benefitTitle3: "Extend Your Selling Power to Partners",
    benefitDesc3:
      "Good partner relationships are key to channel sales. With a partner portal, you can give partners the content, tools, and support they need to drive sales.",
    benefitTitle4: "Boost Productivity with Automation and AI",
    benefitDesc4:
      "Give your team automated pricing workflows and custom selling resources. Use AI-powered insights to make your team even more productive.",
    categoryOffer: "industryType",
    offerSection: "healthcare-life-sciences",
    testimonial: {
      title: "See How Enovate Medical’s Solution Drives Results",
      quote:
        "We’re engaging with them digitally, virtually — maybe not face-to-face — but it’s in real-time. It’s how they want to do business.",
      attributionName: "JOEY LEN",
      attributionTitle:
        "Manager of CRM and Account Management, Enovate Medical",
      image: "",
      logo: "enovate-medical.png",
      group: true,
      storyLink:
        "https://www.salesforce.com/resources/customer-stories/medtech-virtual-engagement/",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSBAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuGrAAK",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnImAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 7,
    priority: "Enhance Patient Services in Pharma",
    priorityShort: "Enhance Patient Services in Pharma",
    slug: "enhance-patient-services-in-pharma",
    description:
      "Enable pharmaceutical teams to connect with patients and providers on one, connected platform delivering personalized engagement and support programs for improved process adherence, cost reduction, and operational efficiency.",
    category: "industryType",
    industryX: "healthcare-life-sciences",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Marketing",
      "MuleSoft",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Deliver Therapy-Specific Patient Support Programs",
    benefitDesc1:
      "Connect data from all systems — old or new, on-premises or in the cloud — on a single, secure platform. Your teams get a complete view of every patient’s needs. AI-powered insights help you scale the right therapy-specific services and programs.",
    benefitTitle2: "Create Personalized Patient Services on Any Channel",
    benefitDesc2:
      "Give patients the personalized support they want, on their favorite channels. From onboarding and adherence to insurance verification and copays.",
    benefitTitle3: "Offer Effective, Ongoing Patient Support",
    benefitDesc3:
      "With an end-to-end connected experience, everyone’s on the same page: providers, payers, and families. Working together, they can support the patient and ensure compliance over the long term.",
    benefitTitle4: "",
    benefitDesc4: "",
    categoryOffer: "industryType",
    offerSection: "healthcare-life-sciences",
    testimonial: {
      title: "See How Piedmont Healthcare's Solution Drives Results",
      quote:
        "The ease of configuration that Salesforce Health Cloud offered provided the patient services team at Novartis with significantly more agility and autonomy, allowing team members to set up and configure highly complex patient care programs themselves, without depending on IT support.",
      attributionName: "Katie Logan",
      attributionTitle: "Chief Consumer Officer, Piedmont Healthcare",
      image: "piedmonthealthcare.png",
      logo: "piedmont.jpg",
      group: true,
      storyLink:
        "https://www.salesforce.com/resources/articles/piedmont-healthcare-covid-response/",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcRrAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuH1AAK",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJIAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Manufacturing
  {
    rank: 46,
    priority: "Modernize Commercial Operations",
    priorityShort: "Modernize Commercial Operations",
    slug: "modernize-commercial-operations",
    description:
      "Elevate seller productivity with workflow automation and AI. Reduce revenue leakage and costs by integrating revenue management on a unified platform.",
    category: "industryType",
    industryX: "manufacturing-automotive",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Sales",
      "Marketing",
      "Commerce",
      "Tableau",
      "MuleSoft",
      "Slack",
      "Industries",
      "Customer Success",
    ],
    benefitTitle1: "Accurately Forecast Customer Demand",
    benefitDesc1:
      "Create a single source of truth for customer data. Build complete forecasts with volume predictions for revenue streams across your business.",
    benefitTitle2: "Drive Virtual Collaboration Across Teams",
    benefitDesc2:
      "Remove silos, work faster, and give stakeholders more visibility. Enable virtual collaboration in real-time for all your teams and respond to urgent needs.",
    benefitTitle3: "Make Data-Driven Business Decisions Fast",
    benefitDesc3:
      "Inform decisions with insights from AI. Empower teams in sales agreement compliance, account health, pricing, and product performance.",
    benefitTitle4: "Enable Real-Time Partner Visibility",
    benefitDesc4:
      "Make it easier to do business with you. Shorten planning times, and increase engagement by sharing forecasts, collaborative tools, and insights with your value chain.",
    categoryOffer: "industryType",
    offerSection: "manufacturing-automotive",
    testimonial: {
      title: "See How KONE's Solution Drives Results",
      quote:
        "What's been so important with Salesforce is that we've been constantly able to develop and drive it to the next level.",
      attributionName: "Henrik Ehrnrooth",
      attributionTitle: "President and CEO, KONE",
      image: "henrik_ehrnrooth.png",
      logo: "kone.jpg",
      storyLink: "https://www.salesforce.com/customer-success-stories/kone/",
    },
    video: {
      videoID: "syWG4HWm2CYrQS7sTMPTT6",
      eyebrow: "Watch Video",
      title:
        "Learn How Manufacturers Foster Agility and Responsiveness to Handle Sudden Changes in Turbulent Times",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSoAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHCAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJOAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 13,
    priority: "Transform the Service Experience",
    priorityShort: "Transform the Service Experience",
    slug: "transform-service-experience",
    description:
      "Reduce service costs and increase employee productivity by delivering faster and smarter service experiences.",
    category: "industryType",
    industryX: "manufacturing-automotive",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Sales",
      "Service",
      "Marketing",
      "Commerce",
      "Tableau",
      "MuleSoft",
      "Slack",
      "Industries",
      "Customer Success",
    ],
    benefitTitle1: "Seamlessly Connect Partners to Your Business",
    benefitDesc1:
      "Extend sales, service, and marketing capabilities with one centralized, customizable platform. Help your team and partners rally around your customers in real time.",
    benefitTitle2: "Build and Optimize Schedules with AI",
    benefitDesc2:
      "Make the most of every moment. Free up your dispatchers with smart, customizable automation tools, so they can schedule the right job to the right resource at the right time.",
    benefitTitle3: "Deliver Cutting-Edge Mobile Experiences Offline",
    benefitDesc3:
      "Put power in the hands of your mobile employees with easy-to-use apps. Vital info like customer history, service details, quality assurance, and tools like remote assistance keep them 100% connected.",
    benefitTitle4: "Provide Proactive Service",
    benefitDesc4:
      "Empower your dispatchers to be more proactive, and then some. With the help of AI recommendations, they always know what steps to take next. And they’re always a step ahead.",
    categoryOffer: "industryType",
    offerSection: "manufacturing-automotive",
    testimonial: {
      title: "See How KONE's Solution Drives Results",
      quote:
        "What's been so important with Salesforce is that we've been constantly able to develop and drive it to the next level.",
      attributionName: "Henrik Ehrnrooth",
      attributionTitle: "President and CEO, KONE",
      image: "henrik_ehrnrooth.png",
      logo: "kone.jpg",
      storyLink: "https://www.salesforce.com/customer-success-stories/kone/",
    },
    video: {
      videoID: "syWG4HWm2CYrQS7sTMPTT6",
      eyebrow: "Watch Video",
      title:
        "Learn How Manufacturers Foster Agility and Responsiveness to Handle Sudden Changes in Turbulent Times",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSpAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002QuHQAA0",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJ0AAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Media
  {
    rank: 17,
    priority: "Accelerate Advertising Sales",
    priorityShort: "Accelerate Advertising Sales",
    slug: "accelerate-advertising-sales",
    description:
      "Stay ahead of new products and find innovative ways to sell more. Understand your advertisers quickly with a single view across all channels and interactions, unlocking data to efficiently and effectively grow advertising revenues.",
    category: "industryType",
    industryX: "media",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Sales",
      "Service",
      "Marketing",
      "Tableau",
      "Data Cloud",
      "Customer Success",
      "Slack",
    ],
    benefitTitle1: "Drive Operational Excellence",
    benefitDesc1:
      "Maximize advertising revenue and simplify the sales process with accelerated insights that grow revenue.",
    benefitTitle2: "Unify Sales Operations",
    benefitDesc2:
      "Integrate all media revenue streams, inventory, and order management systems with a single platform. Easily prioritize opportunities, automate sales processes, and streamline operations.",
    benefitTitle3: "Maximize Productivity",
    benefitDesc3:
      "Sell from anywhere by accessing all selling tools and a unified view of the business, eliminating administrative burdens. Build digital sales aides and integrate them into sales processes so reps can sell faster and more effectively.",
    benefitTitle4: "Sell Smarter with Intelligence",
    benefitDesc4:
      "Leverage unified intelligence to spot trends, act on insights, anticipate customer needs, and drive media revenue. Also, enhance consumer data to drive better targets and maximize yield.",
    categoryOffer: "industryType",
    offerSection: "media",
    // testimonial: {
    //   title: "See How Ticketmaster's Solution Drives Results",
    //   quote:
    //     "We work with Salesforce to create journey-based campaigns, not just through the arc of a season, but also through the arc of a consumer decision-making journey.",
    //   attributionName: "Kathryn Frederick",
    //   attributionTitle: "CMO, Ticketmaster",
    //   image: "kathryn_frederick.png",
    //   logo: "ticketmaster.jpg",
    //   storyLink: "https://www.salesforce.com/customer-success-stories/ticketmaster/",
    // },
    video: {
      videoID: "HfJZkVVPpzqoCdWK69kG93",
      eyebrow: "Watch Video",
      title:
        "Supercharge Teams to Sell Smarter, Faster and Grow Advertising Revenue",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSrAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJajAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m61UAAQ",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 18,
    priority: "Maximize Audience Engagement",
    priorityShort: "Maximize Audience Engagement",
    slug: "maximize-audience-engagement",
    description:
      "Focus your teams on audience acquisition, growth, and creating intelligent interactions throughout every step of the consumer journey. Bridge the gap between the content you make and the fans who consume it — all while saving time and money with AI-powered media experiences your customers love.",
    category: "industryType",
    industryX: "media",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Sales",
      "Service",
      "Marketing",
      "Tableau",
      "MuleSoft",
      "Data Cloud",
      "Customer Success",
      "Slack",
    ],
    benefitTitle1: "Expand Your Audience",
    benefitDesc1:
      "Enrich prospect profiles with first-party data, as well as trusted second- and third-party sources to build a single shared view of every customer. Use intelligent audience segmentation and optimize for driving demand.",
    benefitTitle2: "Personalize Marketing Journeys",
    benefitDesc2:
      "Personalize marketing based on granular consumer interests and insight, and connect marketing, sales, service, and commerce for unified and intelligent engagement.",
    benefitTitle3: "Create Impactful Communities",
    benefitDesc3:
      "Create branded forums and engagement platforms to power fan-to-fan interaction. Turn fans into expert ambassadors through Q&A, groups, forums, and personalized feeds. Enable self-help and account management to scale service and integrate cross-channel and partner engagement opportunities.",
    benefitTitle4: "Power Intelligent Interactions",
    benefitDesc4:
      "Capture audience insights and understand each person’s affinities and intent. With that data, you can design audience experiences with AI and boost the relevance of every touchpoint with predictive insights.",
    categoryOffer: "industryType",
    offerSection: "media",
    testimonial: {
      title: "See How Ticketmaster's Solution Drives Results",
      quote:
        "We work with Salesforce to create journey-based campaigns, not just through the arc of a season, but also through the arc of a consumer decision-making journey.",
      attributionName: "Kathryn Frederick",
      attributionTitle: "CMO, Ticketmaster",
      image: "kathryn_frederick.png",
      logo: "ticketmaster.jpg",
      storyLink:
        "https://www.salesforce.com/customer-success-stories/ticketmaster/",
    },
    video: {
      videoID: "YiN8jCxT9bG4M5fG6NEgZF",
      eyebrow: "Watch Video",
      title: "Engage Every Fan with Personalized Journeys, Everywhere",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcT3AAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJafAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJhAAI",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Public sector
  {
    rank: 59,
    priority: "Digitize License, Permit and Inspection Processes",
    priorityShort: "Digitize License, Permit and Inspection Processes",
    slug: "digitize-license-permit-and-inspection-processes",
    description:
      "Reduce the cost of each step in the license, permit, and inspection lifecycle by taking a digital-first approach. Guide constituents through application requirements for their business, occupational, or individual needs. Get inspectors the information they need to increase inspection effectiveness and elevate compliance.",
    category: "industryType",
    industryX: "public-sector",
    roleX: "",
    businessTypeX: "",
    productGrid: ["Industries", "Slack", "Customer Success"],
    benefitTitle1: "Accelerate Application Reviews",
    benefitDesc1:
      "Give public sector employees timely notifications for upcoming tasks, including clear next actions for each step in the application to ensure no critical steps are missed.",
    benefitTitle2: "Connect Constituents and Public Servants",
    benefitDesc2:
      "Increase collaboration between applicants and supporting public sector employees with digital-first tools.",
    benefitTitle3: "Help Constituents Get Answers Fast",
    benefitDesc3:
      "Provide an online, self-service hub for constituents to find, submit, and check applications or inspection status quickly and easily.",
    benefitTitle4: "Elevate Inspections Effectiveness",
    benefitDesc4:
      "Provide inspectors with a complete view of scheduled visits and assigned tasks from their mobile device.",
    categoryOffer: "industryType",
    offerSection: "public-sector",
    testimonial: {
      title: "See How The State of Rhode Island's Solution Drives Results",
      quote:
        "This strategy gives us the tools to keep pace, the data to evolve, [and] a foundation that has an immediate impact, as well as lasting impact on how we respond – not react – to the future.",
      attributionName: "Bijay Kumar",
      attributionTitle: "CIO, the State of Rhode Island",
      image: "",
      logo: "RI.jpg",
      storyLink:
        "https://www.salesforce.com/customer-success-stories/state-of-rhode-island/",
    },
    video: {
      videoID: "Lpeh1jMjrGkQAmF7Y9usX6",
      eyebrow: "Watch Video",
      title: "3 Steps to Modernize License, Permit and Inspection Processes",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002K7VYAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJbDAAW",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIYAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 62,
    priority: "Modernize Employee Engagement",
    priorityShort: "Modernize Employee Engagement",
    slug: "modernize-employee-engagement",
    description:
      "Reduce disconnected processes, siloed help resources, and paper-based administrative burdens that impact mission readiness and hinder your ability to recruit and manage talent effectively. With a unified view of employees and fast, digital operations, organizations can transform hiring and recruiting, HR personnel actions, IT requests, and employee services — all within a trusted hub.",
    category: "industryType",
    industryX: "public-sector",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Marketing",
      "Data Cloud",
      "Slack",
      "Tableau",
      "MuleSoft",
      "Customer Success",
    ],
    benefitTitle1: "Hire Candidates Faster",
    benefitDesc1:
      "Quickly launch customized digital sites to showcase career opportunities, capture candidate information, communicate with recruits, and guide them through every step of the government hiring process.",
    benefitTitle2: "Streamline Everyday Operations",
    benefitDesc2:
      "Centralize HR, IT, and operational services like personnel action requests, employee badging, or VPN issues in a unified administration hub with guided, digital flows designed to fast-track employee requests.",
    benefitTitle3: "Increase Mission Readiness",
    benefitDesc3:
      "Easily assign training and address talent gaps with scalable workforce management services and a complete view of employee capabilities, certifications, and skill sets.",
    benefitTitle4: "Protect Sensitive Data",
    benefitDesc4:
      "Secure background checks, personnel files, and sensitive employee information within a trusted platform built to meet the current and evolving needs of the public sector.",
    categoryOffer: "industryType",
    offerSection: "public-sector",
    testimonial: {
      title: "See How The U.S. Air Force’s Solution Drives Results",
      quote:
        "We’re now in a place where we can match the market in terms of agility while also reducing cost and complexity",
      attributionName: "Joseph Burgener",
      attributionTitle: "Director CRM Transformation, AFPC",
      image: "",
      logo: "us_air_force.png",
      storyLink:
        "https://org62.my.salesforce.com/sfc/p/#000000000062/a/3y000001Req0/tyNYbt7Kebfn5JvuHutyBAI2le2Cg3CocVEJV0eYnLE",
    },
    video: {
      videoID: "GbrBTe44HWkH2nTKFq68z8",
      eyebrow: "Watch Video",
      title: "See how to modernize the employee experience",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002K7VhAAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJazAAG",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIVAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Retail
  {
    rank: 9,
    priority: "Deliver Seamless Commerce Experiences",
    priorityShort: "Deliver Seamless Commerce Experiences",
    slug: "deliver-seamless-commerce-experience",
    description:
      "Shoppers often interact with nine different channels before making a purchase, often jumping between web, mobile, social, and in-store. To earn lifetime loyalty, retailers must deliver personalized and seamless purchasing and service experiences across ever-changing channels.",
    category: "industryType",
    industryX: "retail",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Marketing",
      "Commerce",
      "Service",
      "Data Cloud",
      "Customer Success",
      "Slack",
    ],
    benefitTitle1: "Engage Seamlessly Across All Channels",
    benefitDesc1:
      "Ensure a consistent brand experience at every touchpoint and automate offers to capture shoppers with one click. Offer relevant, personalized content and messaging in every channel powered by AI.",
    benefitTitle2: "Create Customer-Centric Journeys",
    benefitDesc2:
      "Enable real-time interaction management with shoppers and take knowing your customer to the next level with AI and 1-to-1 messaging at scale.",
    benefitTitle3: "Provide Inventory and Order Visibility",
    benefitDesc3:
      "Give shoppers the option to take control. Optimize the shopping experience with intelligent, always-on access to the information that’s important to them.",
    benefitTitle4: "Serve Customers Across Any Channel",
    benefitDesc4:
      "Deliver differentiated experiences and connect with shoppers in their preferred channel. Scale support with AI-powered chatbots, automation, and self-service portals.",
    categoryOffer: "industryType",
    offerSection: "retail",
    testimonial: {
      title: "See How e.l.f. Cosmetic's Solution Drives Results",
      quote:
        "We use Salesforce for the full customer journey, from discovery to post-purchase, which builds that [customer] relationship and, ultimately, their advocacy.",
      attributionName: "Ekta Chopra",
      attributionTitle: "VP of Digital, e.l.f. Cosmetics",
      image: "ekta_chopra.png",
      logo: "elf.jpg",
      storyLink:
        "https://www.salesforce.com/resources/guides/elf-cosmetics-customer-journey/",
    },
    video: {
      videoID: "RDbcWZ1gwHNAdTPeDp3iKi",
      eyebrow: "Watch Story",
      title:
        "e.l.f. Cosmetics Makes Every Customer Journey Beautiful with Salesforce",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcSFAA0",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002a0cMAAQ",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnJKAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 10,
    priority: "Acquire and Engage Loyal Customers",
    priorityShort: "Acquire and Engage Loyal Customers",
    slug: "acquire-and-engage-loyal-customers",
    description:
      "Optimize and activate your customer data to see shoppers as people, not numbers. Manage large amounts of data with generative AI, uncovering customer insights to drive loyalty. With a unified platform, your teams can personalize shopper experiences from end to end.",
    category: "industryType",
    industryX: "retail",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Marketing",
      "Commerce",
      "Service",
      "Tableau",
      "MuleSoft",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Optimize Your Marketing ROI",
    benefitDesc1:
      "Measure and drive marketing transformation with data-driven decisions. Use real-time insights coupled with AI to optimize marketing spend and campaign efficiency.",
    benefitTitle2: "Know and Grow Your Audiences",
    benefitDesc2:
      "Attract new customers through persona-based targeting, and create a unified customer profile across every touchpoint.",
    benefitTitle3: "Deliver Amazing Customer Experiences",
    benefitDesc3:
      "Ensure timely promotion delivery and see how to adapt engagement based on shopper preferences and real-time behavior.",
    benefitTitle4: "Drive Loyalty with Personalized Engagements",
    benefitDesc4:
      "Deliver the right message, at the right time, at scale. Use AI and cross-channel insights to tailor marketing actions, aligning with audiences and shopper engagement.",
    categoryOffer: "industryType",
    offerSection: "retail",
    testimonial: {
      title: "See How e.l.f. Cosmetic's Solution Drives Results",
      quote:
        "We use Salesforce for the full customer journey, from discovery to post-purchase, which builds that [customer] relationship and, ultimately, their advocacy.",
      attributionName: "Ekta Chopra",
      attributionTitle: "VP of Digital, e.l.f. Cosmetics",
      image: "ekta_chopra.png",
      logo: "elf.jpg",
      storyLink:
        "https://www.salesforce.com/resources/guides/elf-cosmetics-customer-journey/",
    },
    video: {
      videoID: "RDbcWZ1gwHNAdTPeDp3iKi",
      eyebrow: "Watch Story",
      title:
        "e.l.f. Cosmetics Makes Every Customer Journey Beautiful with Salesforce",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002hNmQAAU",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJbNAAW",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002pnIKAAY",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Transportation Hospitality
  {
    rank: 19,
    priority:
      "Deliver Personalized Service at Every Stage of the Travel Journey",
    priorityShort:
      "Deliver Personalized Service at Every Stage of the Travel Journey",
    slug: "deliver-personalized-service-at-travel-journey",
    description:
      "Use unified analytics to streamline processes and save costs with flexible integration and AI. Integrate marketing, booking, and service applications to deliver in-context experiences. Weave data into all customer-facing applications from mobile apps to kiosks to pricing engines and beyond.",
    category: "industryType",
    industryX: "travel-transportation-hospitality",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Sales",
      "Marketing",
      "Service",
      "MuleSoft",
      "Tableau",
      "Net Zero",
      "Customer Success",
    ],
    benefitTitle1: "Create a Single View of Your Customer",
    benefitDesc1:
      "Build a complete traveler profile based on booking history, social media, interests, lifestyles, and preferences.",
    benefitTitle2: "Increase Bookings with 1-to-1 Marketing Journeys",
    benefitDesc2:
      "Create hyperpersonalized, 1-to-1 marketing journeys to inspire their next trip.",
    benefitTitle3: "Deliver Personalized Offers",
    benefitDesc3:
      "Increase engagement with personalized offers that reflect each customer’s passions and interests. Send offers at the right time through their preferred channels, on any device.",
    benefitTitle4: "Provide Enhanced Service",
    benefitDesc4:
      "Help agents provide better service with customer data. See all the vital, relevant information you need — like travel history and interests — all in one place.",
    categoryOffer: "industryType",
    offerSection: "travel-transportation-hospitality",
    // testimonial: {
    //   title: "See How Maersk's Solution Drives Results",
    //   quote:
    //     "To ensure a seamless service, we need to empower both our staff and our customers to engage across multiple channels – both traditional and digital.",
    //   attributionName: "Peter Hartz",
    //   attributionTitle:
    //     "Senior Director of Customer Experience and Service, Maersk",
    //   image: "peter_hartz.png",
    //   logo: "maersk.jpg",
    //   storyLink: "",
    // },
    // video: {
    //   videoID: "4zdpQ9twuLttZktjYtFnLB",
    //   eyebrow: "Watch Story",
    //   title:
    //     "Learn How Maersk Makes Waves with Greater Digitalization and Personalization",
    // },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcT4AAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJcBAAW",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m61jAAA",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 20,
    priority: "Deliver Seamless Shipping",
    priorityShort: "Deliver Seamless Shipping",
    slug: "deliver-seamless-shipping",
    description:
      "Put shippers and consignees on the same page throughout the shipping journey. Streamline shipping by integrating all your systems into one solution, powered by AI. Handle complex requests with personalized, proactive service. And deliver a seamless experience at every stage — from quoting and booking to shipment, tracking, and fulfillment.",
    category: "industryType",
    industryX: "travel-transportation-hospitality",
    roleX: "",
    businessTypeX: "",
    productGrid: [
      "Industries",
      "Sales",
      "Service",
      "Marketing",
      "MuleSoft",
      "Tableau",
      "Customer Success",
      "Slack",
    ],
    benefitTitle1: "Drive More Revenue From New and Current Customers",
    benefitDesc1:
      "Target, attract, and acquire new customers with the right messaging. Stay connected with shippers and identify opportunities to drive more revenue through upsell and cross-sell.",
    benefitTitle2: "Provide a 360-Degree View of Shippers and Consignees",
    benefitDesc2:
      "Connect all your systems for review through a single control tower. Bring marketing, sales, service, and engagement together to find key information fast.",
    benefitTitle3:
      "Experience More Transparency Throughout the Shipment Lifecycle",
    benefitDesc3:
      "Let shippers and consignees see quotes, pricing, fulfillment, tracking, and logistics in real time. Allow customers to easily make changes and place reorders.",
    benefitTitle4: "Integrate Your Systems Into a Single View",
    benefitDesc4:
      "Combine data from all your current systems of record (TMS, WMS, FMS, ERP). And get a simplified view that’s accessible to users in the office and in the field.",
    categoryOffer: "industryType",
    offerSection: "travel-transportation-hospitality",
    testimonial: {
      title: "See How Maersk's Solution Drives Results",
      quote:
        "To ensure a seamless service, we need to empower both our staff and our customers to engage across multiple channels – both traditional and digital.",
      attributionName: "Peter Hartz",
      attributionTitle:
        "Senior Director of Customer Experience and Service, Maersk",
      image: "peter_hartz.png",
      logo: "maersk.jpg",
      storyLink: "",
    },
    video: {
      videoID: "4zdpQ9twuLttZktjYtFnLB",
      eyebrow: "Watch Story",
      title:
        "Learn How Maersk Makes Waves with Greater Digitalization and Personalization",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002UcT7AAK",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002EJcCAAW",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002m61oAAA",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  // Data
  {
    rank: 20,
    priority: "Modernize Your Analytics with Tableau",
    priorityShort: "Modernize Your Analytics with Tableau",
    slug: "modernize-your-analytics-with-tableau",
    description:
      "Make better data-driven decisions with powerful, fast, and digestible insights at your fingertips. Tableau is integrated into Customer 360 and provides an end-to-end data and analytics platform, ensuring responsible data use with AI, visual analytics, and collaboration.",
    category: "roleType",
    industryX: "",
    roleX: "data",
    businessTypeX: "",
    productGrid: ["Tableau", "Data Cloud", "Slack", "Customer Success"],
    benefitTitle1: "Bring Your Data to Life with Generative AI",
    benefitDesc1:
      "Identify high-performing business drivers for prioritization, facilitating more informed and effective decision-making across your organization with AI.",
    benefitTitle2: "Make Data Easy for Everyone",
    benefitDesc2:
      "Enhance your team’s productivity with AI-powered insights that eliminate manual spreadsheet calculations and laborious dashboard setup to give every employee access to accurate data.",
    benefitTitle3: "Trusted Data",
    benefitDesc3:
      "Bring efficiencies of scale to streamline governance, security, compliance, maintenance, and support with solutions for the entire lifecycle with Tableau, including automatically notifying users of deprecated underlying data sources.",
    benefitTitle4: "Flexible Deployment",
    benefitDesc4:
      "Create data structures that move and evolve with you by plugging into your existing infrastructure in the cloud or directly into your CRM.",
    categoryOffer: "roleType",
    offerSection: "data",
    testimonial: {
      title: "See How F5’s Solution Drives Results",
      quote:
        "Data has been transforming our corporate culture, right in front of our eyes. I feel like every morning, I wake up, and I’m learning something new about data.",
      attributionName: "Amie Bright",
      attributionTitle: "RVP of Enterprise Data Strategy and Insights at F5",
      image: "",
      logo: "f5.png",
      storyLink: "",
    },
    video: {
      videoID: "zzNkRiZx38SpFsXJtNfdSx",
      eyebrow: "Watch Story",
      title: "Start transforming your business with data-driven decisions",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002aQKPAA2",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002aQKPAA2",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002aQLXAA2",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 20,
    priority: "Make Your Data an Asset",
    priorityShort: "Make Your Data an Asset",
    slug: "make-data-an-asset",
    description:
      "Unleash the potential of your unique data to generate new revenue streams. Enable your team with impactful insights for faster, better business decisions. With Tableau Embedded Analytics, offer seamless, data-rich experiences across your organization, customizing visualizations to fit your brand.",
    category: "roleType",
    industryX: "",
    roleX: "data",
    businessTypeX: "",
    productGrid: [
      "Tableau",
      "Data Cloud",
      "MuleSoft",
      "Slack",
      "Customer Success",
    ],
    benefitTitle1: "Increase Business Profitability",
    benefitDesc1:
      "See your business by the numbers with intuitive dashboards that identify opportunities for revenue growth, increased customer loyalty, and areas of development.",
    benefitTitle2: "Automate Insights",
    benefitDesc2:
      "Drive efficiencies and cost savings by embedding data-driven processes into buyer and supplier channels.",
    benefitTitle3: "Power Better Business Decisions",
    benefitDesc3:
      "Provide suppliers with connected, real-time insights into their brands, from primary sales data to category web analytics.",
    benefitTitle4: "Monetize Your Data",
    benefitDesc4:
      "Transform your data into dollars. Treat data as a valuable business asset to create new revenue streams, enhance customer relationships, and improve operational efficiency.",
    categoryOffer: "roleType",
    offerSection: "data",
    testimonial: {
      title: "See How Ocado’s Solution Drives Results",
      quote:
        "Tableau helps our retail supply partners to achieve clarity. The dashboards we deliver provide the embedded analytics they need to become more agile, putting data at the center of their decision-making",
      attributionName: "JACK JOHNSON",
      attributionTitle: "Head of Retail Data at Ocado Retail",
      image: "",
      logo: "ocado.png",
      storyLink:
        "https://www.tableau.com/solutions/customer/ocado-retail-monetises-its-wealth-data-tableau-embedded-analytics",
    },
    video: {
      videoID: "N9VFwj5WTsdvZBySTyBN9G",
      eyebrow: "Watch Story",
      title: "Seamlessly Embed the Self-Service Analytics Your Customers Want",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002aQKUAA2",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002aQKUAA2",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002aQLcAAM",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
  {
    rank: 20,
    priority: "Enhance the Customer 360 with Data",
    priorityShort: "Enhance the Customer 360 with Data",
    slug: "enhance-customer-360-with-data",
    description:
      "Quick access to real-time customer data is crucial for understanding and engaging customers effectively. Help your organization know your customer, understand every moment, and act on real-time insights across Customer 360, enabling personalized and connected experiences customers want.",
    category: "roleType",
    industryX: "",
    roleX: "data",
    businessTypeX: "",
    productGrid: ["Tableau", "Data Cloud", "Slack", "Customer Success"],
    benefitTitle1: "Know Your Customer",
    benefitDesc1:
      "Gain a 360-degree view of each customer by combining data from across your Salesforce stack, allowing you to engage with relevancy.",
    benefitTitle2: "Understand Every Moment",
    benefitDesc2:
      "Unlock deep customer insights in real time through limitless customer data exploration and visualizations, driving meaningful personalization at scale.",
    benefitTitle3: "Action Real-Time Customer Data and Insights ",
    benefitDesc3:
      "Empower customer-facing teams with actionable insights directly within their Salesforce workflow, delivering intelligent, connected, and personalized experiences your customers love.",
    benefitTitle4: "AI-Powered Predictions",
    benefitDesc4:
      "Accelerate decision-marking with AI-powered predictions and recommendations, enabling confident collaboration and foster superior business outcomes.",
    categoryOffer: "roleType",
    offerSection: "data",
    testimonial: {
      title: "See How Avaya’s Solution Drives Results",
      quote:
        "CRM Analytics lets us link the analytics to our business processes by embedding these insights right within the CRM workflow. No other tool could give us that ability without a huge investment in custom development",
      attributionName: "Mariano Salatino",
      attributionTitle: "Vice President of Sales Operations at Avaya",
      image: "",
      logo: "avaya.jpg",
      storyLink: "https://www.salesforce.com/customer-success-stories/avaya/",
    },
    video: {
      videoID: "97FXb6UwprYMkBFaom9njT",
      eyebrow: "Watch Story",
      title: "Tableau for Salesforce Customer 360",
    },
    formUrl: {
      default: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002aQKZAA2",
        driver: "d=7013y000002czjbAAA",
      },
      email: {
        url: "https://www.salesforce.com/form/contact/customer360solution/",
        campaignId: "nc=7013y000002aQKZAA2",
        driver: "d=7013y000002EHjsAAG",
      },
      formula1: {
        url: "https://www.salesforce.com/form/f1-campaign/",
        campaignId: "nc=7013y000002aQLhAAM",
        driver: "d=7013y000002Uw0ZAAS",
      },
    },
  },
];
