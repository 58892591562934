module.exports = {
  analytics: "GTM-K4S25JQ",
  choose: "Choose your ",
  intro: {
    hero: {
      title: "Create a Customer 360 tailored to your business needs.",
      body: "Create a Customer 360 with best-in-class CRM apps to improve customer relationships and drive better business results. With CRM + AI + Data + Trust working together in Einstein 1, your Customer 360 is more powerful than ever.",
      arrowsText: [
        "Answer four quick questions to get your personalized  recommendation",
        "See results based on your industry, role, company size, and goals",
        "Explore the products included in your solution, including demos and customer stories",
      ],
      cta: "Find your solution",
      eyebrowAlt: "Customer 360",
      formula1: {
        body: "Enter for your chance to win an F1® Grand Prix™ experience when you explore your personalized Customer 360 solution.",
        cta: "Get started",
        disclaimer:
          "No purchase necessary. Void where prohibited. Program Period Runs 9:00am 03/15/23 to 11:59pm 10/15/23 PT. See Sweep Entry Periods in Rules. Races include FORMULA 1 BRITISH GRAND PRIX 2023, FORMULA 1 UNITED STATES GRAND PRIX 2023, and FORMULA 1 LAS VEGAS GRAND PRIX 2023. Open to US, Canada (ex Quebec), Ireland, NZ and UK residents. Must be 21+. See ",
        rules: {
          text: "Official Rules",
          link: "https://www.salesforce.com/content/dam/web/en_us/www/documents/legal/no-index/formula1-salesforcecustomer-360sweepstakes-officialrules.pdf",
        },
        disclaimer2:
          "The F1® logo, FORMULA 1, F1®, GRAND PRIX and related marks are trademarks of Formula One Licensing BV, a Formula 1 company. All rights reserved.",
      },
    },
    hero_event: {
      title: "Wow your customers with Customer 36O.",
      body: "Customize your Customer 360 solution. Answer a few simple questions and we'll show you solutions perfectly fitted to your needs, size, and industry.",
      cta: "Get Started",
      eyebrowAlt: "Customer 360",
    },
    wheel: {
      title: "Add up the difference Einstein 1 makes.",
      subTitle: "See your business through your customers’ eyes.",
      stats: [
        { multi: "29", title: "Increase in Business User Productivity*" },
        { multi: "30", title: "Increase in Customer Retention*" },
        { multi: "27", title: "Increase in Win Rate*" },
      ],
      ctaStart: "Find your solution",
      ctaCustomer: "Explore Customer 360",
      informationText:
        "Customers expect more from your business. With Customer 360, the world’s #1 CRM, you can deliver. See how uniting every team around a single, shared view of real-time customer data helps lower costs, drive efficient growth, and exceed customer expectations every time.",
      citation:
        "*FY23 Salesforce Customer Success Metrics Survey, conducted 2022 among 3700+ customers randomly selected. Response sizes per question vary. Numbers represent Salesforce customers' average improvement using our products across key categories.",
    },
    video: {
      videoID: "uGjpmjGQX3sHRbATB7tFcx",
      eyebrow: "CUSTOMER 360",
      title:
        "Learn how to bring all your teams together with a unified view of your customer.",
      ctaStart: "Get Started",
      ctaCustomer: "What is customer 360?",
    },
  },
  tracker: {
    solutions: "Solutions for the",
    role: "in",
    industry: "for",
    priority: "that need to",
    moreIndicator: "MORE",
    defaultTypes: {
      role: "Role",
      roleActive: "roles ",
      industry: "Industry",
      industryActive: "industry ",
      businessType: "Business Type",
      priority: "Goal",
    },
  },
  roles: {
    slug: "role",
    title: "What is your role?",
    subTitle:
      "Let us know your area of interest so we can uncover the best option for you.",
  },
  industries: {
    slug: "industry",
    title: "What industry is your business in?",
    subTitle: "Find a solution tailored to your specific needs.",
  },
  businessType: {
    slug: "business",
    title: "What size is your organization?",
    subTitle: "Help us match you to the right products.",
  },
  priorities: {
    slug: "priority",
    industryChoicePrefix: "You chose",
    title: "What is your biggest goal?",
    subTitle:
      "Select your top priority. Repeat the experience and discover more solutions.",
    loadMore: "View more priorities",
  },
  recommendations: {
    yourCustomer: "Your Customer 360 Solution",
    industryChoicePrefix: "You chose",
    priorityChoicePrefix: "You want to prioritize",
    benefitTitle: "How Your Customer 360 Solution Helps You Get Results",
    conditionalModuleTitle: "You May Also Like",
    conditionalModulesubTitle:
      "The Latest Stories From The 360 Blog, Every Week",
    conditionalModuleCta: "SUBSCRIBE",
    ctaLink: "https://www.salesforce.com/form/other/blog-newsletter/",
    loadingText: "Generating Your Customer 360 Solution",
    lockupSolution: {
      eyebrow: "Your Customer 360 Solution",
      eyebrowAlt: "Your Customer 360 Solution",
      cta: "GET IN TOUCH",
    },
    wheel: {
      title: "List of your selected products.",
    },
    lockupAlternatePriority: {
      title: "Select and Explore Alternate Goals",
    },
    lockupProducts: {
      eyebrow: "Your customer 360 products",
      title:
        "Learn More About the Products in Your Customer 360 Solution and How They Work Together",
      body: "Explore your Customer 360 Solution to see how uniting these products help you reach your goal faster.",
      ctaNextSteps: "Next Steps",
    },
    share: {
      eyebrow: "Share your customer 360 solution",
      body: "Save this for yourself or share it with your colleagues.",
      copy: "Share Solution",
      copySuccess: "Copied!",
      copyLink: "Copy Link",
      copyLabel: "Copy the link to share this page with your team.",
    },
    contact: {
      nextStep: "Take the Next Step",
      eyebrow: "Get in touch",
      title: "Talk to an Expert",
      body: "Learn more about your Customer 360 and other custom solutions from the leaders in CRM. Let’s get started.",
      cta: "Contact Us",
      ctaLink: "/",
    },
    roi: {
      citation:
        "FY23 Salesforce Customer Success Metrics Survey, conducted 2022 among 3,700+ customers randomly selected. Response sizes per question vary.",
    },
    portraitOf: "Portrait photo of",
    selectGoal: "Select Another Goal",
    businessNeeds:
      "Choose a different solution that's right for your business needs.",
    startOver: "Start Over",
    moreSolutions:
      "Repeat the experience and discover more Customer 360 solutions.",
    retakeQuiz: "Or Start Again",
    hiddenAltSpan: "Learn more about",
    testimonialCustomerStory: "READ THE CUSTOMER STORY ",
    restart: {
      eyebrow: "Explore other solutions",
      title: "Take the quiz one more time.",
      cta: "Get Started",
    },
  },
  header: {
    contact: "Contact Us",
    logoAlt: "Salesforce - Home",
    dotcom: {
      url: "https://www.salesforce.com",
      text: "Salesforce.com",
    },
  },
  notFound: {
    title: "Looks like you’ve lost your way!",
    cta: "Head Back Home",
  },
  timeline: {
    id: "timeline",
    title: "What is Customer 360?",
    tlItems: [
      {
        id: 0,
        tlItemTitle:
          "Customer 360 is our complete portfolio of technology and services.",
        tlItemContent: "Salesforce is built with the end customer in mind.",
      },
      {
        id: 1,
        tlItemTitle: "Your customer is always at the center of your business.",
        tlItemContent:
          "And they expect connected, personalized experiences across your company.",
      },
      {
        id: 2,
        tlItemTitle:
          "The icons around your customer represent Salesforce’s suite of products.",
        tlItemContent:
          "We unite marketing, sales, commerce, service, IT and analytics and provide a single, shared view of every customer. All on one integrated platform, powered by our global community of Trailblazers.",
      },
      {
        id: 3,
        tlItemTitle:
          "Together, these products create solutions for your specific business and customer needs. ",
        tlItemContent: "Find your own Customer 360 solution today.",
      },
    ],
    cta: "Get Started",
  },
  footer: {
    copyright: "\u00a9 Copyright 2023 Salesforce.com, Inc. ",
    rightsReserved: {
      url: "https://www.salesforce.com/company/legal/intellectual/",
      text: "All\xa0rights\xa0reserved",
    },
    logoAlt: "Salesforce - Home",
    trademarks:
      ". Various trademarks held by their respective owners. Salesforce.com, Inc. ",
    address:
      "Salesforce Tower, 415 Mission Street, 3rd Floor, San\xa0Francisco,\xa0CA\xa094105, United\xa0States",
    phone: "General Inquiries: 1-800-NO-SOFTWARE",
    links: [
      {
        url: "https://www.salesforce.com/company/legal/",
        text: "Legal",
      },
      {
        url: "https://www.salesforce.com/company/legal/sfdc-website-terms-of-service.jsp",
        text: "Terms of Service",
      },
      {
        url: "https://www.salesforce.com/company/privacy/",
        text: "Privacy",
      },
      {
        url: "https://www.salesforce.com/company/disclosure/",
        text: "Responsible Disclosure",
      },
      {
        url: "https://trust.salesforce.com/",
        text: "Trust",
      },
      {
        url: "https://www.salesforce.com/form/contact/contactme.jsp?d=70130000000EeYa",
        text: "Contact",
      },
      {
        text: "Cookie Preferences",
        id: "ot-sdk-btn",
        classes: "optanon-toggle-display",
      },
      {
        id: "privacy-choices",
        url: "https://www.salesforce.com/form/other/privacy-request/",
        text: "Your Privacy Choices",
      },
    ],
  },
};
