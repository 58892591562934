import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { CLASSES, ANALYTICS } from "../../utils/constants";
import { AnswerContext } from "../../context/answer-context";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/answer-context";

const NextButton = ({ ...props }) => {
  const { next, isActive, curValue } = props;
  const [isBouncing, setIsBouncing] = useState(false);
  const navigate = useNavigate();
  const { searchParamsToString } = useContext(GlobalContext);

  useEffect(() => {
    curValue ? setIsBouncing(true) : setIsBouncing(false);
  }, [curValue]);

  const handleClick = () => next();
  const handlePrioritySubmit = (priority, url, isFormula) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "eventTracker",
        eventCat: ANALYTICS.CATEGORY.QUIZ,
        eventAct: ANALYTICS.ACTION.CHALLENGE,
        eventLbl: priority.title,
      },
    });
    navigate(url + searchParamsToString());
  };

  return (
    <AnswerContext.Consumer>
      {({ ...state }) => (
        <div>
          {state.priorityChoice ? (
            <button
              form={state.formID}
              className={`next-btn sf-row sf-row-xs--center sf-row-xs--middle ${
                !state.selectAnotherGoal ? isActive && CLASSES.ACTIVE : ""
              } ${
                isBouncing && !state.selectAnotherGoal ? CLASSES.ANIMATING : ""
              }`}
              onAnimationEnd={() => {
                setIsBouncing(false);
              }}
              onClick={(e) => {
                e.preventDefault();
                handlePrioritySubmit(
                  state.priorityChoice,
                  state.recommendedURL,
                  state.isFormula
                );
              }}
              disabled={!isActive}
              aria-label="Next"
            >
              <Arrow />
            </button>
          ) : (
            <button
              form={state.formID}
              className={`next-btn sf-row sf-row-xs--center sf-row-xs--middle ${
                isActive && CLASSES.ACTIVE
              } ${isBouncing ? CLASSES.ANIMATING : ""}`}
              onClick={handleClick}
              onAnimationEnd={() => {
                setIsBouncing(false);
              }}
              disabled={!isActive}
              aria-label="Next"
            >
              <Arrow />
            </button>
          )}
        </div>
      )}
    </AnswerContext.Consumer>
  );
};

export default NextButton;
